export default function Exclamation() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
        >
            <path
                d="M11.5101 10.1097V11.8741M11.5101 15.403H11.5189M5.39792 18.9318H17.6222C18.9805 18.9318 19.8294 17.4615 19.1503 16.2852L13.0381 5.69862C12.359 4.52233 10.6612 4.52233 9.98204 5.69862L3.86988 16.2852C3.19075 17.4615 4.03966 18.9318 5.39792 18.9318Z"
                stroke="#171717"
                strokeWidth="1.56646"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
