import { useSyncExternalStore } from "use-sync-external-store/shim";
import errorExternalStore from "../observables/errorExternalStore";

export default function useErrorExternalStore() {
    const store = useSyncExternalStore(
        errorExternalStore.subscribe,
        errorExternalStore.getSnapshot,
    );

    return store;
}
