import jwtDecode from "jwt-decode";
import {
    COGNITO_FEDERATED_GOOGLE_SIGNIN_URL,
    COGNITO_FEDERATED_ISSUER,
    COGNITO_FEDERATED_SIGNOUT_URL,
    CORE_API_HOSTNAME,
    cognito,
} from "../env";
import fulfill from "../fulfill";
import * as AuthApi from "../types/management-auth";
import { forgetDeviceSignOut } from "./cognitoIdentityWrapper";
import {
    clearCognitoSession,
    getCognitoSession,
    setCognitoSession,
} from "./localStorage";
import { AuthenticatedUser, CognitoSession } from "./types";

/**
Dintero backoffice auth flow

(0. Read cognito session to local storage and go to 3.)
1. Redirect the user to aws cognito login page
2. In callback from cognito read the cognito-token from the location hash.
3. Using the cognito-token as a Bearer token, get data about the user with GET /account/user https://docs.dintero.com/api.html#operation/account_user_get
4. At the / route the user is redirected to the most recently used account by the RedirectToAccount component
5. At the /{account_id} prefixed routes the WithAccountAccessToken uses the cognito-token to get a dintero-token for fetch requests to the dintero APIs.
 */

const getSessionFromLocationHash = (): CognitoSession | undefined => {
    // On a successfull login AWS Cognito redirects users to the backoffice with the access_token in the window.location.hash
    // Eg. https://example.com/#access_token=<jwt>
    const hash = window.location.hash;
    const prefix = "#";
    if (hash.startsWith(prefix)) {
        const paramsString = hash.substring(prefix.length);
        const params = new URLSearchParams(paramsString);

        let cognito_access_token = params.get("access_token");
        if (
            cognito().external ||
            federatedSignin.isFederatedSignin(params.get("id_token"))
        ) {
            // id token is used when authenticating user with
            // external cognito as the id token includes the email
            // of the user
            cognito_access_token = params.get("id_token");
        }

        const cognito_expires = params.get("expires_in");
        if (cognito_access_token && cognito_expires) {
            const session = {
                cognito_access_token,
                cognito_expires,
            };
            return session;
        }
    }
};

const login = () => {
    clearCognitoSession();
    window.location.assign(cognito().login);
};

const redirectToLogout = () => {
    window.location.assign(cognito().logout);
};

const logout = (forgetDevice: boolean) => {
    const cognitoSession = clearCognitoSession();

    if (
        cognitoSession &&
        federatedSignin?.isFederatedSignin(cognitoSession.cognito_access_token)
    ) {
        return federatedSignin.signout(window);
    }

    if (cognito().external || !forgetDevice) {
        return redirectToLogout();
    }

    return forgetDeviceSignOut()
        .then(() => redirectToLogout())
        .catch(() => redirectToLogout());
};

const resetLocationHash = () => {
    window.location.hash = "";
};

const getAccounts = async (cognitoToken: string) => {
    const headers = { Authorization: `Bearer ${cognitoToken}` };
    const response: AuthApi.AuthenticatedAccountUser | null = await fulfill.get(
        {
            url: `${CORE_API_HOSTNAME}/v1/account/user`,
            headers,
            accountId: "none",
            handlers: {
                200: (
                    json: AuthApi.AuthenticatedAccountUser,
                ): AuthApi.AuthenticatedAccountUser => json,
                401: () => {
                    clearCognitoSession();
                },
                403: () => {
                    clearCognitoSession();
                },
            },
        },
    );
    return response;
};

type InitializeAuthFunction = (
    prevSession: CognitoSession | undefined,
) => Promise<AuthenticatedUser | undefined>;

const authenticate: InitializeAuthFunction = async (
    prevSession?: CognitoSession,
) => {
    const session = prevSession || getSessionFromLocationHash();
    if (session) {
        const user = await getAccounts(session.cognito_access_token);
        resetLocationHash();
        if (user) {
            setCognitoSession(session);
            return {
                ...session,
                account_user: user,
            };
        }
    }
};

export const isFederatedSignin = () => {
    const token = getCognitoSession();
    return federatedSignin.isFederatedSignin(
        token?.cognito_access_token || null,
    );
};

const federatedSignin = {
    setRedirect: (pUrl: string, name: "redirect_uri" | "logout_uri") => {
        const url = new URL(pUrl);
        const searchParams = url.searchParams;
        searchParams.set(name, window.location.origin);
        url.search = searchParams.toString();
        return url.toString();
    },

    redirect: (window: Window) => {
        window.location.href = federatedSignin.setRedirect(
            COGNITO_FEDERATED_GOOGLE_SIGNIN_URL,
            "redirect_uri",
        );
    },

    signout: (window: Window) => {
        window.location.href = federatedSignin.setRedirect(
            COGNITO_FEDERATED_SIGNOUT_URL,
            "logout_uri",
        );
    },

    isFederatedSignin: (idToken: string | null): boolean => {
        if (!idToken) {
            return false;
        }

        const decodedToken = jwtDecode(idToken) as {
            iss?: string;
            [key: string]: any;
        };
        const issuer = decodedToken?.iss;
        const expectedIssuer = COGNITO_FEDERATED_ISSUER;
        return issuer === expectedIssuer;
    },
};

export { authenticate, login, logout, getAccounts, federatedSignin };
