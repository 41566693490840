// Based on Mantine 7.9.0 @mantine/hooks/use-resize-observer

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type ObserverRect = Omit<DOMRectReadOnly, "toJSON">;

const defaultState: ObserverRect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};

export const useResizeObserver = <T extends HTMLElement = any>() => {
    const frameId = useRef(0);
    const [node, setNode] = useState<T | null>(null);
    const [rect, setRect] = useState<ObserverRect>(defaultState);

    const ref = useCallback((node: T) => {
        setNode(node);
    }, []);

    const observer = useMemo(() => {
        if (typeof ResizeObserver === "undefined") {
            return null;
        }

        return new ResizeObserver((entries) => {
            const entry = entries[0];

            if (entry) {
                cancelAnimationFrame(frameId.current);
                frameId.current = requestAnimationFrame(() => {
                    if (node) {
                        setRect(
                            entry.target
                                .getBoundingClientRect()
                                .toJSON() as ObserverRect,
                        );
                    }
                });
            }
        });
    }, [node]);

    useEffect(() => {
        if (node) {
            observer?.observe(node, {
                box: "border-box",
            });
        }

        return () => {
            observer?.disconnect();
            if (frameId.current) {
                cancelAnimationFrame(frameId.current);
            }
        };
    }, [node, observer]);

    return { ref, rect };
};

export default function useElementSize<T extends HTMLElement = any>() {
    const { ref, rect } = useResizeObserver<T>();

    return { ref, rect };
}
