import parseISO from "date-fns/parseISO";

export const formatDate = (isoString: string | undefined, language: string) => {
    if (!isoString) {
        return "";
    }
    const date = parseISO(isoString);
    const yyyy = new Intl.DateTimeFormat(language, {
        year: "numeric",
    })
        .format(date)
        .replace(/\D/g, "");
    const mm = new Intl.DateTimeFormat(language, {
        month: "2-digit",
    })
        .format(date)
        .replace(/\D/g, "");
    const dd = new Intl.DateTimeFormat(language, {
        day: "2-digit",
    })
        .format(date)
        .replace(/\D/g, "");
    return `${yyyy}-${mm}-${dd}`;
};

export const formatTime = (isoString: string | undefined, language: string) => {
    if (!isoString) {
        return "";
    }
    const date = parseISO(isoString);
    return Intl.DateTimeFormat(language, {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
    }).format(date);
};
