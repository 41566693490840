import AppBarMerchantLogo from "../AppBar/AppBarMerchantLogo";
import { Button } from "../Buttons";
import { Icon, LeftPane, RightPane, Wrapper } from "../TopBar/elements";
import docsSvg from "../TopBar/icons/docs.svg";
import helpSvg from "../TopBar/icons/help.svg";
import View from "../View";

export default function TopBarPlaceholder() {
    return (
        <Wrapper>
            <View width="100%" justify="space-between" className="main-toolbar">
                <LeftPane>
                    <AppBarMerchantLogo icon="profile" />
                </LeftPane>
                <RightPane>
                    <Button className="icon top-bar">
                        <Icon icon={docsSvg} />
                    </Button>
                    <Button className="icon top-bar">
                        <Icon icon={helpSvg} />
                    </Button>
                </RightPane>
            </View>
        </Wrapper>
    );
}
