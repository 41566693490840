import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Button, ButtonGroup } from "../../../components/Buttons";
import { Input } from "../../../components/Forms";
import { P } from "../../../components/Typography";
import { validate, validateRequired } from "../../../helpers/validation";
import { colors, distances, grid } from "../../../styles/constants";
import { forgottenPassword } from "../../cognitoIdentityWrapper";
import { stringToSlug } from "../helpers";

interface ForgotPasswordProps {
    username: string;
    setSubmitUsername: (username: string) => void;
    setBack: (username: string) => void;
    setShake: (value: boolean) => void;
}

const ForgotPassword = ({
    username,
    setBack,
    setSubmitUsername,
    setShake,
}: ForgotPasswordProps) => {
    const { t } = useTranslation();
    const [loginError, setLoginError] = useState("");
    const formik = useFormik({
        initialValues: {
            username: username,
        },
        validate: (values) => {
            let errors: { [key: string]: string } = {};
            const validators = [
                {
                    path: "username",
                    validator: validateRequired(t("login.invalid.username")),
                },
            ];
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);

            return errors;
        },
        onSubmit: async (values) => {
            try {
                await forgottenPassword(values.username.toLowerCase());
                setSubmitUsername(values.username);
            } catch (error) {
                console.log("Error forgot password ", error);
                setLoginError(error.message);
                setShake(true);
            }
        },
    });
    return (
        <>
            <P style={{ textAlign: "center" }}>
                {t("login.forgot_password_description")}
            </P>
            <ErrorMessage visible={loginError !== ""}>
                {t("login.forgot_password_error", {
                    context: stringToSlug(loginError),
                })}
            </ErrorMessage>
            <form onSubmit={formik.handleSubmit}>
                <InputWrapper>
                    <Input
                        label={t("login.fields.username")}
                        placeholder={t(`login.placeholders.username`)}
                        type="email"
                        name="username"
                        autoComplete="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus={true}
                        disabled={formik.isSubmitting}
                        required
                    />
                </InputWrapper>
                <StyledButtonGroup>
                    <Button
                        type="submit"
                        className="stretch"
                        disabled={!formik.isValid || formik.isSubmitting}
                    >
                        {t("login.actions.forgotten_password")}
                    </Button>
                    <Button
                        className="outlined stretch"
                        onClick={() => {
                            setBack(formik.values.username);
                        }}
                    >
                        {t("login.actions.back")}
                    </Button>
                </StyledButtonGroup>
            </form>
        </>
    );
};

const InputWrapper = styled.div`

    &:last-child {
        margin: 0;
    }
`;

const StyledButtonGroup = styled(ButtonGroup)`
    display: flex;
    flex-direction: column;
    gap: var(--Spacing-3);
    @media (max-width: 768px) {
        margin-right: ${distances.tiny};
    }
`;

interface ErrorMessageProps {
    visible: boolean;
}

const ErrorMessage = styled.p<ErrorMessageProps>`
    text-align: center;
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
    visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

export default ForgotPassword;
