import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { globalMobileBreakpoint, palette } from "../../styles/constants";

import campaignsActive from "./icons/campaigns-active.svg";
import customersActive from "./icons/customers-active.svg";
import ordersActive from "./icons/orders-active.svg";
import partnerActive from "./icons/partner-active.svg";
import paymentsActive from "./icons/payments-active.svg";
import settingsActive from "./icons/settings-active.svg";

import chevronIcon from "./icons/chevron.svg";
import dashboardIcon from "./icons/dashboard.svg";
import payoutIcon from "./icons/payout.svg";
import reportsIcon from "./icons/reports.svg";

const iconMap = {
    payments: paymentsActive,
    reports: reportsIcon,
    customers: customersActive,
    campaigns: campaignsActive,
    orders: ordersActive,
    backoffice: dashboardIcon,
    settings: settingsActive,
    partner: partnerActive,
    payout: payoutIcon,
};

interface AppBarIconProps extends React.DOMAttributes<HTMLSpanElement> {
    icon: keyof typeof iconMap;
    to: string;
    title: string;
    exact?: boolean;
    className?: string;
    withChevron?: boolean;
}

const AppBarIconLink = ({
    icon,
    to,
    title,
    exact,
    className,
    withChevron = false,
}: AppBarIconProps) => {
    return (
        <AppBarIconElem
            to={to}
            activeClassName="active"
            exact={exact || false}
            className={className}
        >
            <Icon icon={iconMap[icon]} />
            <StyledP>{title}</StyledP>
            {withChevron && <Chevron />}
        </AppBarIconElem>
    );
};

interface AppBarIconElemProps {
    icon: string;
}

const Icon = styled.div.attrs({ className: "icon" })<AppBarIconElemProps>`
    mask-image: url(${(props) => props.icon});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${palette.neutral[800]};
    height: 20px;
    width: 20px;
    margin-right: 8px;
`;

const Chevron = styled.div.attrs({ className: "chevron" })`
    mask-image: url(${chevronIcon});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${palette.neutral[800]};
    height: 20px;
    width: 20px;
    margin-left: 8px;
`;

const StyledP = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    align-self: stretch;
    flex: 1;
`;

const AppBarIconElem = styled(NavLink)`
    position: relative;
    background: transparent;
    width: 100%;
    display: flex;
    text-decoration: none;
    user-select: none;
    margin: 0;
    padding: 8px;
    color: ${palette.neutral[800]};
    transition: all 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    cursor: pointer;

    &:hover {
        border-color: ${palette.primary[500]};
        color: ${palette.neutral[800]};
    }
    &.active:hover,
    &.active {
        background-color: unset;
        border-color: ${palette.primary[500]};
        border-width: 2px;
        color: ${palette.primary[500]};
        .chevron {
            background-color: ${palette.primary[500]};
            rotate: 180deg;
        }
        .icon {
            background-color: ${palette.primary[500]};
        }
    }
    @media (max-width: ${globalMobileBreakpoint}px) {
        width: auto;
        height: 48px;
        background-position: 10%;
        border-left: none;
        border-bottom: 1px solid transparent;
        background-image: none;
        padding: 14px 12px;
        display: flex;
        line-height: 20px;
    }
`;

export default AppBarIconLink;
