import React from "react";
import styled from "styled-components/macro";
import { distances } from "../../styles/constants";
import Button from "./Button";

interface IconButtonProps {
    icon: any;
    className?: string;
    onClick?: () => void;
    type?: "submit" | "reset" | "button";
    placement?: "left" | "right";
    [key: string]: any;
}

const IconButton = ({
    children,
    icon,
    placement,
    className,
    ...props
}: IconButtonProps) => {
    const actualPlacement = placement || "right";
    return (
        <ButtonWrapper className={className}>
            <IconButtonButton
                {...props}
                className={[className || "", actualPlacement].join(" ")}
            >
                {placement === "left" && (
                    <IconLeftWrapper>{icon}</IconLeftWrapper>
                )}
                <ChildrenWrapper placement={placement}>
                    {children}
                </ChildrenWrapper>
                {placement !== "left" && (
                    <IconRightWrapper>{icon}</IconRightWrapper>
                )}
            </IconButtonButton>
        </ButtonWrapper>
    );
};

const IconButtonButton = styled(Button)`
    &.left {
        padding-left: 40px;
    }
    &.right {
        padding-right: 40px;
    }
`;

const ButtonWrapper = styled.div`
    position: relative;
    display: inline-block;

    &.stretch {
        min-width: 100%;
    }
`;

const IconLeftWrapper = styled.div`
    position: absolute;
    left: ${distances.small};
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconRightWrapper = styled.div`
    position: absolute;
    right: ${distances.small};
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface ChildrenWrapperProps {
    placement?: "left" | "right";
}

const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
    margin-left: ${(props) => (props.placement === "left" ? "15px" : "inherit")};
    color: inherit;
`;

export default IconButton;
