import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
    function ExclamationIcon(props, ref) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                ref={ref}
                {...props}
            >
                <path
                    d="M10 8.43245V9.99999M10 13.1351H10.0078M4.56988 16.2702H15.4301C16.6368 16.2702 17.391 14.9639 16.7877 13.9188L11.3575 4.5136C10.7542 3.46857 9.24582 3.46857 8.64247 4.5136L3.21235 13.9188C2.609 14.9639 3.36319 16.2702 4.56988 16.2702Z"
                    stroke="currentColor"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    },
);
