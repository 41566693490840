// Loosely based on Mantine 7.9+ Accordion component

import { useMemo, useState } from "react";
import { AccordionProvider } from "./Context";

type AccordionProps = {
    children: React.ReactNode;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
};

type UseUncontrolledInput<T> = {
    value?: T;
    defaultValue?: T;
    finalValue?: T;
    onChange?: (value: T, ...payload: any[]) => void;
};

function useUncontrolled<T>({
    value,
    defaultValue,
    finalValue,
    onChange = () => {},
}: UseUncontrolledInput<T>): [
    T,
    (value: T, ...payload: any[]) => void,
    boolean,
] {
    const [uncontrolledValue, setUncontrolledValue] = useState(
        defaultValue !== undefined ? defaultValue : finalValue,
    );

    const handleUncontrolledChange = (newValue: T, ...payload: any[]) => {
        setUncontrolledValue(newValue);
        onChange?.(newValue, ...payload);
    };

    if (value !== undefined) {
        return [value, onChange, true];
    }

    return [uncontrolledValue as T, handleUncontrolledChange, false];
}

export default function Accordion({
    children,
    value,
    defaultValue,
    onChange,
}: AccordionProps) {
    const _id = useMemo(
        () => `accordion-${Math.random().toString(36).substr(2, 9)}`,
        [],
    );

    const [_value, handleChange] = useUncontrolled({
        value,
        defaultValue,
        finalValue: undefined,
        onChange,
    });

    const isItemActive = (id: string) => {
        if (Array.isArray(_value)) {
            return _value.includes(id);
        }
        return _value === id;
    };

    const handleItemChange = (id: string) => {
        const nextValue = () => {
            if (Array.isArray(_value)) {
                if (_value.includes(id)) {
                    return _value.filter((current) => current !== id);
                }
                return [..._value, id];
            }
            if (_value === id) {
                return undefined;
            }
            return id;
        };
        handleChange(nextValue() as string);
    };

    return (
        <AccordionProvider
            value={{
                isItemActive,
                onChange: handleItemChange,
                getControlId: (id: string) => `${_id}-control`,
                getRegionId: (id: string) => `${_id}-panel`,
            }}
        >
            {children}
        </AccordionProvider>
    );
}
