import styled, { keyframes } from "styled-components/macro";

import {
    colors,
    defaultRadius,
    distances,
    globalMobileBreakpoint,
    globalPageMaxWidth,
} from "../styles/constants";
import { H2 } from "./Typography";

interface CardProps {
    children?: any;
    title?: any;
    subtitle?: string;
    noPadding?: boolean;
    marginBottom?: string;
    shortHr?: boolean;
    id?: string;
    fitContent?: boolean;
    className?: string;
    overflowHidden?: boolean;
    style?: React.CSSProperties;
}

const Card = (props: CardProps) => {
    return (
        <CardWrapper
            className={props.className}
            noPadding={props.noPadding}
            marginBottom={props.marginBottom}
            overflowHidden={props.overflowHidden}
            fitContent
            style={props.style}
        >
            {props.title && (
                <TitleWrapper noPadding={props.noPadding}>
                    <H2 id={props.id}>{props.title}</H2>
                    <CardSubtitle>{props.subtitle}</CardSubtitle>
                    {props.shortHr ? <ShortCardHr /> : <CardHr />}
                </TitleWrapper>
            )}
            {props.children}
        </CardWrapper>
    );
};

interface WrapperProps {
    noPadding?: boolean;
    marginBottom?: string;
    fitContent?: boolean;
}

const TitleWrapper = styled.div<WrapperProps>`
    padding: ${(props) => (props.noPadding ? distances.normal : "0")};
    padding-top: ${(props) => (props.noPadding ? distances.small : "0")};
    padding-bottom: 0;
`;

interface WrapperProps {
    noPadding?: boolean;
    marginBottom?: string;
    fitContent?: boolean;
    overflowHidden?: boolean;
}

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const CardWrapper = styled.section.attrs((props) => ({
    className: ["card", props.className].filter(Boolean).join(" "),
}))<WrapperProps>`
    background: ${colors.background};
    color: ${colors.text};
    padding: ${(props) => (props.noPadding ? "0" : distances.normal)};
    margin-bottom: ${(props) => props.marginBottom || "0px"};
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 8px;
    position: relative;
    border: 1px solid ${colors.borderDark};
    transition: all 0.3s ease;
    height: ${(props) => (props.fitContent ? "fit-content" : "auto")};

    animation: ${fadeIn} 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

    ${(props) => props.overflowHidden && "overflow: hidden;"}

    @media (max-width: ${globalMobileBreakpoint}px) {
        padding: ${(props) => (props.noPadding ? "0" : distances.small)};

        & > h4, p {
            margin: 0;
        }
    }

    & .thead .th:first-of-type {
        border-top-left-radius: 8px;
    }
    & .thead .th:last-of-type {
        border-top-right-radius: 8px;
    }
`;

const CardHr = styled.hr`
    margin: ${distances.tiny} -${distances.normal};
    padding: 0;
    border: 0;
    height: 0;
    background: ${colors.borderLight};
`;

const ShortCardHr = styled.hr`
    padding: 0;
    border: 0;
    height: 2px;
    background: ${colors.borderLight};
`;

const CardSubtitle = styled(H2 as any)`
    color: ${colors.borderDark};
`;

export default Card;
