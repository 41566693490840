import React from "react";
import styled from "styled-components/macro";

import { AsYouType, CountryCode } from "libphonenumber-js";
import {
    border,
    colors,
    defaultRadius,
    distances,
    palette,
} from "../../styles/constants";
import CountryPrefix from "./CountryPrefix";

import { Label } from "../Typography";

export enum ValidationState {
    Pristine = "pristine",
    Valid = "valid",
    Invalid = "invalid",
    Warning = "warning",
}

export interface InputValidation {
    state: ValidationState;
    message?: string;
}

export type OnChangeFunction = (event: Event) => void;

interface InputProps {
    label: any;
    phoneNumber: string;
    countryPrefix: string;
    countryPrefixName: string;
    setFieldValue: (field: string, value: string) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    validation?: InputValidation;
    autoComplete?: string;
    maxLength?: number;
    minLength?: number;
    name: string;
    required?: boolean;
}

const countryCodes: [string, string, string, number | undefined][] = [
    ["+49", "01512 3456789", "DE", undefined],
    ["+48", "512 345 678", "PL", 11],
    ["+47", "406 12 345", "NO", 11], // Can also be formatted XX XX XX XX in some cases
    ["+46", "70-123 45 67", "SE", 13],
    ["+45", "32 12 34 56", "DK", 11],
    ["+44", "07400 123456", "GB", 12],
    ["+358", "041 2345678", "FI", 11],
    ["+354", "611 1234", "IS", 8],
    ["+421", "0912 345 678", "SK", 12],
];

const countryCodePrefixes: string[] = countryCodes.map(
    ([prefix, placeholder]) => prefix,
);

interface StringByString {
    [key: string]: string;
}

const placeholderByPrefix: StringByString = countryCodes.reduce(
    (reduced, [prefix, placeholder]) => {
        return {
            ...reduced,
            [prefix]: placeholder,
        };
    },
    {},
);

const codeByPrefix: StringByString = countryCodes.reduce(
    (reduced, [prefix, placeholder, countryCode]) => {
        return {
            ...reduced,
            [prefix]: countryCode,
        };
    },
    {},
);

const maxLengthByPrefix: { [key: string]: number | undefined } =
    countryCodes.reduce(
        (reduced, [prefix, placeholder, countryCode, maxLength]) => {
            return {
                ...reduced,
                [prefix]: maxLength,
            };
        },
        {},
    );

const PhoneNumber = ({
    label,
    countryPrefix,
    countryPrefixName,
    phoneNumber,
    name,
    autoComplete,
    disabled,
    validation,
    setFieldValue,
    onBlur,
    required,
}: InputProps) => {
    return (
        <Wrapper>
            <Label htmlFor="phone_number">{label}</Label>
            <Inputs>
                <CountryPrefix
                    value={countryPrefix}
                    options={countryCodePrefixes}
                    onChange={(value) => {
                        setFieldValue(countryPrefixName, value);
                    }}
                    disabled={disabled}
                />
                <InputElement
                    value={phoneNumber}
                    id={name}
                    type="tel"
                    autoComplete={autoComplete || "tel-national"}
                    name={name}
                    disabled={disabled}
                    placeholder={placeholderByPrefix[countryPrefix]}
                    onChange={(event: any) => {
                        const value = event.currentTarget.value.trim();
                        const position = event.currentTarget.selectionStart;
                        const wasLastPosition = position === value.length;
                        const phoneFormatter = new AsYouType(
                            codeByPrefix[countryPrefix] as CountryCode,
                        );
                        const formattedNumber = phoneFormatter.input(value);
                        event.currentTarget.value = formattedNumber;
                        if (wasLastPosition) {
                            event.currentTarget.setSelectionRange(
                                formattedNumber.length,
                                formattedNumber.length,
                            );
                        } else {
                            event.currentTarget.setSelectionRange(
                                position,
                                position,
                            );
                        }
                        setFieldValue(name, formattedNumber);
                    }}
                    onBlur={onBlur}
                    className={validation ? validation.state : ""}
                    maxLength={maxLengthByPrefix[countryPrefix]}
                    required={required}
                />
            </Inputs>
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

const Inputs = styled.div`
    display: flex;
    margin-bottom: ${distances.normal};
`;

const InputElement = styled.input`
    box-sizing: border-box;
    border: none;
    outline: none;
    border: ${border.normal} solid ${palette.neutral[400]};
    border-radius: ${defaultRadius};
    background: ${colors.background};
    padding: ${distances.tiny} ${distances.small12};
    text-align: left;
    width: 100%;
    -moz-appearance: textfield;
    font-size: 14px;
    min-height: 40px;
    font-feature-settings: 'tnum';
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);

    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: ${colors.textSecondary};
    }

    &:focus {
        outline: none;
        border: ${border.normal} solid ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};

    }
    &.invalid,
    &:focus .invalid {
        border: ${border.normal} solid ${palette.destructive[300]};
    }

    &.invalid:focus {
        box-shadow: 0 0 0 3px ${palette.destructive[100]};
    }

    &.valid,
    &:focus .valid {
        border: ${border.normal} solid ${palette.success[300]};
    }

    &.valid:focus {
        box-shadow: 0 0 0 3px ${palette.success[100]};
    }

    &:disabled {
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }
`;

const Message = styled.label`
    position: absolute;
    top: 72px;
    left: calc(68px + 40px);
    font-size: 14px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

export default PhoneNumber;
