import React from "react";

const Document = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M6.1111 17H13.8889C14.748 17 15.4444 16.3036 15.4444 15.4444V7.98883C15.4444 7.78255 15.3625 7.58472 15.2166 7.43886L11.0056 3.22781C10.8597 3.08194 10.6619 3 10.4556 3H6.1111C5.25199 3 4.55554 3.69645 4.55554 4.55556V15.4444C4.55554 16.3036 5.25199 17 6.1111 17Z"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Document;
