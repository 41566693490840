import { chunk } from "./chunk";

export const formatNorwegianOrgNumber = (number: string) => {
    return chunk(number, [3, 3, 3]);
};
export const unformatNorwegianOrgNumber = (number: string) => {
    return number.replace(/\s/g, "");
};

export const formatOrgNumber = (country: string, number: string) => {
    if (country === "NO") {
        return formatNorwegianOrgNumber(number.replace(/\s/g, ""));
    }
    return number;
};

export const unformatOrgNumber = (country: string, number: string) => {
    if (country === "NO") {
        return unformatNorwegianOrgNumber(number);
    }
    return number;
};
