export const smallerOrEqual = (comparativeNumber: number, message: string) => {
    return (value: number) => {
        if (!value) {
            return;
        }
        const isValid = value <= comparativeNumber;
        if (!isValid) {
            return message;
        }
    };
};
