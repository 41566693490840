import styled from "styled-components/macro";

import { distances } from "../../styles/constants";
import View from "../View";

const ButtonGroup = styled(View).attrs((props) => ({
    ...props,
    justify: "flex-end",
    gap: distances.small,
    className: "button-group",
}))`
    flex-wrap: wrap;
    @media (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
        & > button {
            width: 100%;
            flex: 1;
        }
        & > div {
            width: 100%;
            flex: 1;
            button {
                width: 100%;
            }
        }
    }
    &[data-sticky-actions="true"] {
        position: sticky;
        bottom: -${distances.normal};
        background: rgba(255, 255, 255, 0.95);
        backdrop-filter: blur(1px);
        margin-inline: -${distances.normal};
        margin-bottom: -${distances.normal};
        padding-inline: ${distances.normal};
        padding-block: ${distances.normal24};
        border-top: solid 1px rgba(0, 0, 0, 0.1);
        @media (max-width: 768px) {
            bottom: -${distances.small};
            margin-inline: -${distances.small};
            margin-bottom: -${distances.small};
            padding-inline: ${distances.small};
            padding-block: ${distances.small};
        }
    }
`;

export default ButtonGroup;
