import type { Action, AnyAction, Reducer } from "redux";

export type PayloadAction<P = any, T extends string = string> = Action<T> & P;

/**
 * An action creator that produces actions with a `payload` attribute.
 */
export interface PayloadActionCreator<P = any, T extends string = string> {
    (): Action<T>;
    (payload: P): PayloadAction<P, T>;
    type: T;
}

/**
 * A utility function to create an action creator for the given action type
 * string. The action creator accepts a single argument, which will be included
 * in the action object as a field called payload. The action creator function
 * will also have its toString() overriden so that it returns the action type,
 * allowing it to be used in reducer logic that is looking for that action type.
 *
 * @param type The action type to use for created actions.
 */
export function createAction<P = any, T extends string = string>(
    type: T,
): PayloadActionCreator<P, T> {
    function actionCreator(): Action<T>;
    function actionCreator(payload: P): PayloadAction<P, T>;
    function actionCreator(payload?: P): Action<T> | PayloadAction<P, T> {
        return { type, ...payload };
    }

    actionCreator.toString = (): T => `${type}` as T;

    actionCreator.type = type;

    return actionCreator;
}

/**
 * Returns the action type of the actions created by the passed
 * `createAction()`-generated action creator (arbitrary action creators
 * are not supported).
 *
 * @param action The action creator whose action type to get.
 * @returns The action type used by the action creator.
 */
export function getActionType<T extends string>(
    actionCreator: PayloadActionCreator<any, T>,
): T {
    return `${actionCreator}` as T;
}

/**
 * Defines a mapping from action types to corresponding action object shapes.
 */
export type Actions<T extends keyof any = string> = Record<T, Action>;

/**
 * An *case reducer* is a reducer function for a speficic action type. Case
 * reducers can be composed to full reducers using `createReducer()`.
 *
 * Unlike a normal Redux reducer, a case reducer is never called with an
 * `undefined` state to determine the initial state. Instead, the initial
 * state is explicitly specified as an argument to `createReducer()`.
 */
export type CaseReducer<S = any, A extends Action = AnyAction> = (
    state: Readonly<S>,
    action: A,
) => S;

/**
 * A mapping from action types to case reducers for `createReducer()`.
 */
export type CaseReducers<S, AS extends Actions> = {
    [T in keyof AS]: AS[T] extends Action ? CaseReducer<S, AS[T]> : void;
};

/**
 * A utility function that allows defining a reducer as a mapping from action
 * type to *case reducer* functions that handle these action types. The
 * reducer's initial state is passed as the first argument.
 *
 * @param initialState The initial state to be returned by the reducer.
 * @param actionsMap A mapping from action types to action-type-specific
 *   case redeucers.
 */
export function createReducer<
    S,
    CR extends CaseReducers<S, any> = CaseReducers<S, any>,
>(initialState: S, actionsMap: CR): Reducer<S> {
    return function (state = initialState, action): S {
        const caseReducer = actionsMap[action.type];
        return caseReducer ? caseReducer(state, action) : state;
    };
}

export type SetStateFunc<S> = Partial<S> | ((state: Readonly<S>) => Partial<S>);

/**
 * Create an action attached to the namespace to set parts of the state (similar to react SetState)
 * Usefull to reduce boilerplate for actions resulting in few and simple changes to the state.
 * It is recommended to use the `reducers` for more complex actions.
 * @param reason is used to create an action type for the changes made to the state (usefull for debugging in devtools)
 * @param setter Either an object, or a function that returns an object, containing parts of the state to update
 */
type NamespacedSetState<S, N extends string> = <Reason extends string>(
    reason: Reason,
    setter: SetStateFunc<S>,
) => PayloadAction<SetStateFunc<S>, `${N}.${Reason}`>;

/**
 * An action creator attached to a namespace.
 */
export type NamespaceActionCreator<P> = (P extends void
    ? () => Action<string>
    : (payload: P) => PayloadAction<P, string>) & { type: string };

export interface SwitchlessReducer<
    S = any,
    AP extends Record<string, unknown> = Record<string, unknown>,
    N extends string = string,
> {
    namespace: N;
    reducer: Reducer<S>;
    actions: {
        [type in keyof AP]: NamespaceActionCreator<AP[type]>;
    };
    setState: NamespacedSetState<S, N>;
}

/**
 * Options for `createSwitchlessReducer()`.
 */
export interface CreateSwitchlessReducerOptions<
    S = any,
    CR extends CaseReducers<S, any> = CaseReducers<S, any>,
> {
    namespace: string;
    initialState: S;

    /**
     * A mapping from action types to action-type-specific *case reducer*
     * functions. For every action type, a matching action creator will be
     * generated using `createAction()`.
     */
    reducers?: CR;

    /**
     * A mapping from action types to action-type-specific *case reducer*
     * functions. These reducers should have existing action types used
     * as the keys, and action creators will _not_ be generated.
     */
    extraReducers?: CaseReducers<S, any>;

    /**
     * Invoked *after* the `reducers` if the action dispatched is in the same namespace.
     * Should not return a new state object unless this reducer produces something to the state.
     */
    namespaceReducer?: CaseReducer<S, any>;

    /**
     * Invoked *after* `namespaceReducer` and `reducers` for all actions dispatched to redux.
     * Should not return a new state object unless this reducer produces something to the state.
     */
    globalReducer?: CaseReducer<S, any>;
}

type CaseReducerActionPayloads<S, CR extends CaseReducers<S, any>> = {
    [T in keyof CR]: CR[T] extends (state: S) => S
        ? void
        : CR[T] extends (state: S, action: PayloadAction<infer P>) => S
          ? P
          : void;
};

const setState = <S>(state: S, action: SetStateFunc<S>) => ({
    ...state,
    ...(typeof action === "function" ? action(state) : action),
});

function enhanceReducer<S, TReducer extends Reducer<S>>(
    namespace: string,
    reducer: TReducer,
    namespaceReducer?: CaseReducer<S, any>,
    globalReducer?: CaseReducer<S, any>,
): Reducer<S> {
    return (state, action) => {
        let nextState = reducer(state, action);
        if (
            namespaceReducer &&
            namespace.length > 0 &&
            action.type.startsWith(namespace)
        ) {
            nextState = namespaceReducer(nextState, action);
        }
        if (
            namespace.length > 0 &&
            action.type.startsWith(`${namespace}.setState`) &&
            !!action.setter
        ) {
            nextState = setState(nextState, action.setter);
        }
        if (globalReducer) {
            nextState = globalReducer(nextState, action);
        }

        return nextState;
    };
}

function getType(namespace: string, actionKey: string): string {
    return namespace ? `${namespace}.${actionKey}` : actionKey;
}

/**
 * A function that accepts an initial state, an object full of reducer
 * functions, a namespace, and automatically generates
 * action creators, and action types that correspond to the
 * reducers and state.
 *
 * The `reducer` argument is passed to `createReducer()`.
 */
export function createSwitchlessReducer<S, CR extends CaseReducers<S, any>>(
    options: CreateSwitchlessReducerOptions<S, CR>,
): SwitchlessReducer<S, CaseReducerActionPayloads<S, CR>> {
    const { namespace, initialState, namespaceReducer, globalReducer } =
        options;
    const reducers: CaseReducers<S, any> = options.reducers || {};

    const actionKeys = Object.keys(reducers);

    const reducerMap = actionKeys.reduce((map, actionKey) => {
        map[getType(namespace, actionKey)] = reducers[actionKey];
        return map;
    }, options.extraReducers || {});

    const reducer = enhanceReducer(
        namespace,
        createReducer(initialState, reducerMap),
        namespaceReducer,
        globalReducer,
    );

    const actions = actionKeys.reduce((map, action) => {
        const type = getType(namespace, action);
        map[action] = createAction(type);
        return map;
    }, {} as any);

    const setState: NamespacedSetState<S, (typeof options)["namespace"]> = (
        reason,
        setter,
    ) => {
        const type = getType(`${namespace}.setState`, reason);
        return createAction(type)({ setter });
    };

    return {
        namespace,
        reducer,
        actions,
        setState,
    };
}
