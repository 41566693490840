// Wraps the localStorage calls in a try catch so that if it fails we don't
// raise any errors. Safari for iOS usually has issues with the localStorage
// API in private mode.

export const getItem = (key: string) => {
    try {
        return window.localStorage.getItem(key);
    } catch {
        return null;
    }
};
export const removeItem = (key: string) => {
    try {
        window.localStorage.removeItem(key);
    } catch {}
};
export const setItem = (key: string, value: string) => {
    try {
        window.localStorage.setItem(key, value);
    } catch {}
};
