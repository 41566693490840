import styled from "styled-components/macro";

import { Link } from "react-router-dom";
import { distances, palette } from "../../styles/constants";
import DinteroLogo from "./icons/dintero-primary.svg";

type AppBarDinteroLogoProps = {
    color?: string;
};

const AppBarDinteroLogo = ({ color }: AppBarDinteroLogoProps) => {
    return (
        <Centered as={Link} to="/">
            <AppBarLogoImage color={color} />
        </Centered>
    );
};

const AppBarLogoImage = styled.div<{ color?: string }>`
    width: 120px;
    mask-image: url(${DinteroLogo});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${({ color }) => color || palette.neutral[900]};
    height: 40px;
`;

const Centered = styled.div`
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export default AppBarDinteroLogo;
