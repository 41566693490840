import React from "react";

const InfoCircle = () => (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none">
        <path
            d="M12.7381 14.8245H11.8626V11.3226H10.9871M11.8626 7.8207H11.8714M19.7418 11.3226C19.7418 15.6742 16.2142 19.2018 11.8626 19.2018C7.51104 19.2018 3.9834 15.6742 3.9834 11.3226C3.9834 6.97101 7.51104 3.44336 11.8626 3.44336C16.2142 3.44336 19.7418 6.97101 19.7418 11.3226Z"
            stroke="#0000FF"
            strokeWidth="1.56646"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default InfoCircle;
