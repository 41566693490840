import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ENVIRONMENT } from "./env";

export default (() => {
    // An IIFE that is exported and then imported at the root of App.tsx
    if (
        process.env.REACT_APP_GITHUB_BRANCH &&
        process.env.REACT_APP_SOURCE_VERSION
    ) {
        // Enables sentry error tracking for `master` and `production` branches.
        Sentry.init({
            dsn: "https://d06a82439e314d08a7a9cd501b01740e@sentry.io/1726231",
            integrations:
                ENVIRONMENT === "prod" ? [] : [new BrowserTracing({})],
            release: process.env.REACT_APP_SOURCE_VERSION,
            environment: process.env.REACT_APP_GITHUB_BRANCH,

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
        });
    }
})();
