import { compose, createStore } from "redux";
import { NODE_ENV } from "./env";
import { initialState, reducer } from "./reducer";

const getComposeEnhancer = () => {
    // https://github.com/zalmoxisus/redux-devtools-extension
    if (NODE_ENV === "development") {
        return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }
    return compose;
};

const composeEnhancers = getComposeEnhancer();
const store = createStore(reducer, initialState, composeEnhancers());

export default store;
