import * as Sentry from "@sentry/react";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import NotFound from "./NotFound";
import UncaughtComponentError from "./UncaughtComponentError";

interface WithNotFoundState {
    hasError: boolean;
}

class WithNotFound extends Component<any, WithNotFoundState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error: any, info: any) {
        console.error(error, info);
        Sentry.captureException(error);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <UncaughtComponentError />;
        }
        return (
            <Switch>
                {this.props.children}
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default WithNotFound;
