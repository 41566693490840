import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {
    colors,
    distances,
    globalMobileBreakpoint,
    palette,
    zIndex,
} from "../../styles/constants";
import { Button } from "../Buttons";
import View from "../View";
import anchorSvg from "./icons/anchor.svg";
import dinteroIcon from "./icons/dintero-icon.svg";

type WrapperProps = {
    isInspecting?: boolean;
    isTest?: boolean;
    isPaymentsNotEnabled?: boolean;
    isBackdropActive?: boolean;
};

export const Wrapper = styled.header<WrapperProps>`
    position: fixed;
    inset-inline: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;

    max-height: var(--topbar-height);
    min-height: var(--topbar-height);
    z-index: 1000;
    top: 0;
    transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    color: ${colors.text};
    ${(props) =>
        props.isBackdropActive &&
        `
        z-index: ${zIndex.top + 10};
    `}
    .main-toolbar {
        background: #ffffffee;
        border-bottom: 1px solid ${colors.borderDark};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        width: 100%;
        padding-inline: 16px;
        padding-block: 12px;
        max-height: var(--topbar-main-height);
        min-height: var(--topbar-main-height);
        transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
        color: ${colors.text};
        ${(props) =>
            props.isTest &&
            `
            .toggle-area {
                background: ${palette.warning[500]};
            }
        `}

        @media (min-width: ${globalMobileBreakpoint + 1}px) {
            ${(props) =>
                props.isInspecting &&
                `
                background: ${palette.primary[50]}ee;
                border-bottom: 1px solid ${palette.primary[200]}ee;
            `}

            ${(props) =>
                props.isTest &&
                `
                background: ${palette.warning[50]}ee;
                border-bottom: 1px solid ${palette.warning[200]}ee;
            `}
        }
        @media (max-width: ${globalMobileBreakpoint}px) {
            padding-inline: ${distances.small};
        }
    }
    svg {
        background-color: ${palette.neutral[700]};
    }
`;

export const Icon = styled.svg.attrs({
    className: "icon",
})<{ icon: string; color?: string }>`
    width: 24px;
    height: 24px;
    mask-image: url(${(props) => props.icon});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: inherit;
    cursor: pointer;
`;

export const HiddenInMobile = styled.div`
    display: contents;
    @media (max-width: ${globalMobileBreakpoint}px) {
        display: none;
    }
`;

export const HiddenInDesktop = styled.div`
    display: contents;
    @media (min-width: ${globalMobileBreakpoint + 1}px) {
        display: none;
    }
`;

export const LeftPane = styled(View).attrs({ gap: "16px" })`
    @media (max-width: ${globalMobileBreakpoint}px) {
        gap: ${distances.small};
    }
`;

export const RightPane = styled(View).attrs({ gap: "24px" })`
    @media (max-width: ${globalMobileBreakpoint}px) {
        gap: ${distances.small};
    }
`;

export const DinteroIcon = styled.div`
    background: url(${dinteroIcon}) no-repeat center;
    background-size: contain;
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

export const TestModeBadge = styled.div`
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 44px;
    background: ${palette.warning[500]};
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
`;

export const BottomTooltip = styled.div<{
    content: string;
}>`
    position: relative;
    display: inline-block;
    cursor: pointer;

    &::before {
        content: '${(props) => props.content}';
        position: absolute;
        background: ${palette.neutral[900]};
        color: white;
        border-radius: 3px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        z-index: 10;
        bottom: -100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &::after {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        background: ${palette.neutral[900]};
        clip-path: polygon(0 0, 100% 0, 0 100%);
        z-index: 10;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &:hover::before {
        opacity: 1;
    }

    &:hover::after {
        opacity: 1;
    }

    &[data-hidden='true'] {
        &:hover::before {
            opacity: 0;
        }

        &:hover::after {
            opacity: 0;
        }
    }
`;

export const AnchorLink = styled.a.attrs({
    className: "anchor-link",
})`
    color: ${palette.primary[500]};
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    &::after {
        content: '';
        background: url(${anchorSvg}) no-repeat center;
        margin-left: auto;
        width: 16px;
        height: 16px;
    }
    &:hover {
        text-decoration: underline;
    }
`;

export const AppLink = styled(Link).attrs({
    className: "app-link",
})<{ disabled?: boolean }>`
    color: ${palette.primary[500]};
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    &:hover {
        background: ${palette.primary[50]};
    }
    ${(props) =>
        props.disabled &&
        `
        pointer-events: none;
        opacity: 0.5;
    `}
`;

export const AppLinkButton = styled(Button).attrs({
    className: "app-link-button",
})`
    color: ${palette.primary[500]};
    background: transparent;
    border: 1px solid transparent;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    &:hover {
        background: ${palette.primary[50]};
        border: 1px solid ${palette.primary[50]};
    }
`;

export const Divider = styled.div`
    width: calc(100% + 16px);
    height: 1px;
    background: ${colors.borderDark};
    margin-block: 8px;
    margin-inline: -8px;
`;

export const EnvBar = styled(View)<WrapperProps>`
    background: ${colors.backgroundAlt};
    border-bottom: 1px solid ${colors.borderDark};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-inline: 16px;
    padding-block: 8px;
    max-height: var(--topbar-indicator-height);
    min-height: var(--topbar-indiactor-height);

    ${(props) =>
        props.isPaymentsNotEnabled &&
        `
        background: ${palette.success[500]};
        border-bottom: 1px solid ${palette.success[200]};
    `}

    ${(props) =>
        props.isInspecting &&
        `
        background: ${palette.primary[50]};
        border-bottom: 1px solid ${palette.primary[200]};
    `}

    ${(props) =>
        props.isTest &&
        `
        background: ${palette.warning[50]};
        border-bottom: 1px solid ${palette.warning[200]};
    `}


    ${(props) =>
        props.isTest || props.isInspecting || props.isPaymentsNotEnabled
            ? ``
            : `
        display: none;`}
`;
