import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import styled, { keyframes } from "styled-components/macro";

import { useEffect, useRef } from "react";
import {
    colors,
    defaultRadius,
    distances,
    grid,
    palette,
    zIndex,
} from "../styles/constants";
import Icon from "./Icons";

export interface ModalProps {
    children: any;
    fixedWidth?: boolean;
    width?: string;
    height?: string;
    solidBackdrop?: boolean;
    overflowY?: "scroll" | "auto" | "hidden" | "visible";
    onClose?: () => void;
    withCloseButton?: boolean;
    fullscreen?: boolean;
    wrapperStyle?: React.CSSProperties;
    backdropStyle?: React.CSSProperties;
}

const Modal = ({
    fixedWidth,
    width,
    height,
    children,
    solidBackdrop,
    overflowY,
    onClose,
    fullscreen,
    backdropStyle,
    wrapperStyle,
    withCloseButton = true,
}: ModalProps) => {
    const modalElement = useRef<HTMLHeadingElement>(null);
    useEffect(() => {
        if (modalElement && modalElement.current) {
            disableBodyScroll(modalElement.current);
        }
        return () => clearAllBodyScrollLocks();
    });
    return (
        <Backdrop
            solidBackdrop={solidBackdrop}
            data-fullscreen={fullscreen}
            style={backdropStyle}
        >
            {onClose && children && <EscapeListener onEscape={onClose} />}
            {onClose && <BackdropTrigger onClick={onClose} />}
            <ModalWrapper
                ref={modalElement}
                width={fullscreen ? "100%" : width}
                height={fullscreen ? "100%" : height}
                fixedWidth={fixedWidth || false}
                overflowY={overflowY || "scroll"}
                data-fullscreen={fullscreen}
                style={wrapperStyle}
            >
                {onClose && withCloseButton && (
                    <CloseButtonGroup>
                        <IconButton type="button" onClick={onClose}>
                            <Icon icon="close_small" />
                        </IconButton>
                    </CloseButtonGroup>
                )}
                {children}
            </ModalWrapper>
        </Backdrop>
    );
};

const EscapeListener = ({ onEscape }: { onEscape: () => void }) => {
    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onEscape();
            }
        };
        window.addEventListener("keydown", handleEscape);
        return () => {
            window.removeEventListener("keydown", handleEscape);
        };
    }, [onEscape]);
    return null;
};

interface ModalWrapperProps {
    fixedWidth: boolean;
    width?: string;
    height?: string;
    overflowY: "scroll" | "auto" | "hidden" | "visible";
}

const appear = keyframes`
    from {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const ModalWrapper = styled.section<ModalWrapperProps>`
    background: ${colors.background};
    color: ${colors.text};
    max-width: ${(props) => (props.fixedWidth ? grid.spans.span6 : "80vw")};
    min-width: 578px;
    border-radius: ${defaultRadius};
    position: absolute;
    top: 10%;
    max-height: 80%;
    z-index: ${zIndex.top + 1};
    overflow-y: ${(props) => props.overflowY};
    padding: ${distances.normal};
    box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.25);
    ${(props) => (props.width ? `width: ${props.width};` : "")}
    ${(props) => (props.height ? `height: ${props.height};` : "")}

    animation: ${appear} 0.2s cubic-bezier(0.2, 0, 0.2, 1);

    &[data-fullscreen='true'] {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        border-radius: 0;
        padding: 0;
        max-height: 100vh;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
    }


    @media (max-width: 768px) {
        margin: 0;
        min-width: 95%;
        padding: ${distances.small};
        max-height: auto;
        top: 2.5%;
    }

    // hide ugly scrollbars
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

/** Transparent backdrop that covers the entire viewport*/

interface BackdropProps {
    solidBackdrop?: boolean;
}

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Backdrop = styled.div<BackdropProps>`
    background: ${(props) => (props.solidBackdrop ? "rgba(255,255,255,1)" : `${palette.neutral[900]}55`)};
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.top};

    animation: ${fadeIn} 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    width: 100vw;
    height: 100vh;

    max-width: 100vw;
`;

const BackdropTrigger = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.top};
`;

const CloseButtonGroup = styled.div`
    position: absolute;
    right: ${distances.normal};
    top: ${distances.normal};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    z-index: ${zIndex.top + 1};
    @media (max-width: 768px) {
        top: ${distances.normal20};
        right: ${distances.normal20};
    }
`;

const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${colors.text};
    font-size: 1.5rem;
    &:hover {
        color: ${colors.primary};
    }
`;

export default Modal;
