import React from "react";

const EyeStrikethrough = () => (
    <svg
        width="69px"
        height="58px"
        viewBox="0 0 69 58"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="eye-strikethrough" transform="translate(0.000000, 1.000000)">
                <path
                    d="M34.4,36.4 C39,36.4 42.8,32.6 42.8,28 C42.8,23.4 39,19.6 34.4,19.6 C29.8,19.6 26,23.3 26,28 C26,32.7 29.8,36.4 34.4,36.4 Z M34.4,40.6 C27.4,40.6 21.8,35 21.8,28 C21.8,21 27.4,15.4 34.4,15.4 C41.4,15.4 47,21 47,28 C47,35 41.4,40.6 34.4,40.6 Z M47,12 C46.3,11.7 45.5,11.3 44.7,11.1 M44.7,11.1 C41.4,9.9 38,9.3 34.3,9.3 C22.5,9.3 13.1,15.3 5.7,27.9 C13.2,40.9 22.6,47.1 34.3,47.1 C46,47.1 55.6,40.9 63.3,27.9 C58.6,20.2 53.2,14.9 47,12 M50.2,8.9 C57.1,12.6 63.1,18.9 68.1,27.8 C59.5,43.4 48.2,51.2 34.2,51.2 C20.2,51.2 9.2,43.5 0.9,27.9 C9.2,12.7 20.3,5.1 34.3,5.1 C40.1,5.1 45.4,6.4 50.2,8.9 L12.5,46.4"
                    id="Combined-Shape"
                    fill="#0852E0"
                ></path>
                <path
                    d="M12.5,46.4 L53.1,5.8"
                    id="Path"
                    stroke="#FFFFFF"
                    fill="#000000"
                    fillRule="nonzero"
                ></path>
                <path
                    d="M7.1,55.3 L61.7,0.7"
                    id="Path"
                    stroke-opacity="0.5"
                    stroke="#46535F"
                    strokeWidth="4"
                    fill="#000000"
                    fillRule="nonzero"
                ></path>
            </g>
        </g>
    </svg>
);

export default EyeStrikethrough;
