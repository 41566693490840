export const hexadecimalRegex = new RegExp(/^[0-9a-f]+$/);

export const isHexadecimal = (url: string) => {
    return hexadecimalRegex.test(url);
};

export const validateHexadecimal = (message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = isHexadecimal(value);
        if (!isValid) {
            return message;
        }
    };
};
