import { forwardRef } from "react";
import styled from "styled-components/macro";

import Loading from "./Loading";

const LoadingOverlay = forwardRef<HTMLDivElement>((_, ref) => {
    return (
        <Overlay ref={ref}>
            <Loading />
        </Overlay>
    );
});

export default LoadingOverlay;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;
