import React from "react";

const Document = () => (
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="element/top-button" fill="currentColor">
                <g id="icons/document">
                    <path
                        d="M24.8720843,11.9934046 L21.0216252,8.14294553 L21.0602265,11.9816029 L24.8720843,11.9934046 Z M19.5150839,7.5 L10.5,7.5 L10.5,28.5 L25.5,28.5 L25.5,13.4953559 L21.0555824,13.4815957 C20.2348529,13.4790547 19.5685552,12.817378 19.5603024,11.996686 L19.5150839,7.5 Z M10.5,6 L21,6 L27,12 L27,28.5 C27,29.3284271 26.3284271,30 25.5,30 L10.5,30 C9.67157288,30 9,29.3284271 9,28.5 L9,7.5 C9,6.67157288 9.67157288,6 10.5,6 Z M13.5,19.5 L22.5,19.5 L22.5,21 L13.5,21 L13.5,19.5 Z M13.5,24 L22.5,24 L22.5,25.5 L13.5,25.5 L13.5,24 Z"
                        id="Combined-Shape"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

export default Document;
