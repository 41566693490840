import styled, { keyframes } from "styled-components/macro";

import { Button, Menu, MenuItem, Wrapper } from "react-aria-menubutton";
import {
    colors,
    defaultRadius,
    distances,
    palette,
    shadows,
    zIndex,
} from "../../styles/constants";
import Icon from "../Icons";

import { useTranslation } from "react-i18next";
import useElementSize from "../../common/hooks/useElementSize";
import { Label } from "../Typography";

export interface DropdownOption {
    label: any;
    value: string;
}

export type OnChangeFunction = (event: Event) => void;

interface DropdownProps {
    name: string;
    label?: any;
    value: DropdownOption;
    onChange: (option: DropdownOption) => void;
    disabled?: boolean;
    options: DropdownOption[];
    className?: string;
    required?: boolean;
    selectedFormatter?: (option: DropdownOption) => string;
    style?: React.CSSProperties;
    noBottomMargin?: boolean;
}

const Dropdown = ({
    name,
    label,
    value,
    options,
    onChange,
    disabled,
    className,
    required,
    selectedFormatter,
    style,
    noBottomMargin,
}: DropdownProps) => {
    const SelectedComponent =
        value.value === "" ? SelectedPlaceholder : Selected;
    const { t } = useTranslation();
    const { ref, rect } = useElementSize();
    return (
        <StyledWrapper
            onSelection={onChange}
            className={className}
            data-no-bottom-margin={noBottomMargin}
        >
            {label && (
                <Label htmlFor={name}>
                    {label}
                    {required ? "" : t("common.optional")}
                </Label>
            )}
            <ButtonWrapper ref={ref}>
                <SelectedComponent
                    id={name}
                    name={name}
                    disabled={disabled}
                    style={style}
                    type="button"
                >
                    {selectedFormatter ? selectedFormatter(value) : value.label}
                    <OffsetIcon icon="caret_down" isLabel={!!label} />
                </SelectedComponent>
            </ButtonWrapper>
            <Options top={rect.height}>
                <>
                    {options.map((option) => (
                        <Option key={option.value} value={option} type="button">
                            {option.label}
                        </Option>
                    ))}
                </>
            </Options>
        </StyledWrapper>
    );
};

const ButtonWrapper = styled.div`
`;

const StyledWrapper = styled(Wrapper)`
    position: relative;
    margin-bottom: ${distances.normal};
    &[data-no-bottom-margin='true'] {
        margin-bottom: 0;
    }
`;

const Selected = styled(Button)`
    border: none;
    outline: none;
    display: block;
    background: ${({ disabled }) => {
        // Seems like the &:disabled selector doesn't work
        // as expected with the react-aria-menubutton Button component. Atleast not in FF
        return disabled ? palette.neutral[50] : colors.background;
    }};
    padding: 9px ${distances.small12};
    width: 100%;
    min-height: 40px;

    color: ${({ disabled }) => {
        // Seems like the &:disabled selector doesn't work
        // as expected with the react-aria-menubutton Button component. Atleast not in FF
        return disabled ? palette.neutral[500] : colors.text;
    }};
    border: 1px solid;
    border-color: ${({ disabled }) => {
        // Seems like the &:disabled selector doesn't work
        // as expected with the react-aria-menubutton Button component. Atleast not in FF
        return disabled ? palette.neutral[200] : palette.neutral[400];
    }};
    border-radius: ${defaultRadius};
    cursor: pointer;
    text-align: left;

    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);

    .open {
        border-color: ${palette.primary[300]};
    }
    &:focus {
        outline: none;
        border-color: ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};
    }
    &:disabled {
        background: ${palette.neutral[50]};
        border-color: ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }
`;

const SelectedPlaceholder = styled(Selected)`
    color: ${colors.borderDark};
    font-weight: 400;
`;

const OffsetIcon = styled(Icon)<{ isLabel: boolean }>`
    top: ${(props) => (props.isLabel ? "39px" : "10px")};
    position: absolute;
    right: 8px;
`;

type OptionsProps = {
    width?: number;
    top?: number;
    left?: number;
};

const appear = keyframes`
    from {
        transform: translateY(-10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Options = styled(Menu)<OptionsProps>`
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: ${(props) => props.top ?? 42}px;
    left: 0px;
    z-index: ${zIndex.top};
    background-color: ${colors.background};
    box-shadow: ${shadows.small};
    border-radius: ${defaultRadius};
    transition: transform 150ms cubic-bezier(0.2, 0, 0.2, 1), opacity 150ms cubic-bezier(0.2, 0, 0.2, 1);
    transform-origin: top center;
    overflow-x: hidden;
    animation: ${appear} 150ms cubic-bezier(0.2, 0, 0.2, 1);
    max-height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
`;

const Option = styled(MenuItem)`
    margin-inline: ${distances.micro};
    padding: ${distances.small12} ${distances.small12};
    white-space: nowrap;
    cursor: pointer;
    border-radius: ${defaultRadius};
    font-size: 14px;

    &:first-of-type {
        margin-top: ${distances.micro};
    }

    &:last-of-type {
        margin-bottom: ${distances.micro};
    }

    &:focus {
        background-color: ${palette.primary[500]};
        color: white;
        & > * > * {
            color: white;
        }
    }

    &:hover {
        background-color: ${palette.primary[500]};
        color: white;
        & > * > * {
            color: white;
        }
    }
`;

export default Dropdown;
