import _debounce from "lodash/debounce";
import { useLayoutEffect, useState } from "react";

export type WindowSize = {
    width: number;
    height: number;
};

export default function useWindowSize() {
    const [size, setSize] = useState<WindowSize>(() => ({
        width: window.innerWidth,
        height: window.innerHeight,
    }));

    // layout effect because we want this to happen before painting
    useLayoutEffect(() => {
        const resize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // resize can fire a bunch of times in rapid succession, hence:
        // https://bencentra.com/code/2015/02/27/optimizing-window-resize.html
        // 32ms yields 30-ish FPS on resize, which should be more than enough for our case.
        // TODO: Consider taking the debounce timeout as an argument if longer/shorter delays are needed
        const debouncedResize = _debounce(resize, 32);

        window.addEventListener("resize", debouncedResize);
        return () => window.removeEventListener("resize", debouncedResize);
    }, []);

    return size;
}
