import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import type { AnyAction, Reducer } from "redux";

export const useAccountId = () => useParams<{ accountId: string }>().accountId;

const AccountIdChangedActionType = "@@accountIdChanged" as const;

/**
 * This action will be dispatched whenever the :accountId parameter in path changes
 */
type AccountIdChangedAction = {
    type: typeof AccountIdChangedActionType;
    accountId: string;
};

const isAccountIdChangedAction = (
    action: AnyAction,
): action is AccountIdChangedAction =>
    action.type === AccountIdChangedActionType;

export const createAccountIdChangedCaseReducer =
    <S>(reducer: Reducer<S, AccountIdChangedAction>) =>
    (state: S, action: AnyAction) => {
        if (isAccountIdChangedAction(action)) {
            return reducer(state, action);
        }
        return state;
    };

/**
 * @note Only for use in root App.tsx. There can only be one
 */
export const AccountIdChangedDispatcher = () => {
    const accountId = useAccountId();
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch({ type: AccountIdChangedActionType, accountId });
    }, [dispatch, accountId]);

    return null;
};
