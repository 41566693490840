import * as ibantools from "ibantools";
import mod11 from "../mod11";

export const validateNorwegianBankAccountNumber = (message: string) => {
    return (account_number: string) => {
        if (account_number) {
            const trimmed = account_number.replace(/\s/g, "");
            try {
                const numbers = trimmed.replace(/^\D+/g, "");
                if (
                    numbers.length === 11 &&
                    mod11(numbers) === parseInt(numbers.substr(10))
                ) {
                    return;
                } else if (
                    numbers.length === 13 &&
                    mod11(numbers.slice(2)) === parseInt(numbers.substr(12))
                ) {
                    return;
                }
            } catch {}
        }
        return message;
    };
};

export const getAccountNumberValidator = (
    country: string,
    errorMessage: string,
) => {
    if (country === "NO") {
        return validateNorwegianBankAccountNumber(errorMessage);
    }
    return (value: string) => {
        return undefined;
    };
};

export const getIbanValidator = (message: string) => (iban: string) => {
    if (!ibantools.isValidIBAN(iban)) {
        return message;
    }
};
