import React, { Component } from "react";
import styled from "styled-components/macro";

import Downshift from "downshift";
import {
    border,
    colors,
    defaultRadius,
    distances,
    palette,
    shadows,
    zIndex,
} from "../../styles/constants";
import Input from "./Input";
import { InputValidation } from "./validation";

export type OnChangeFunction = (event: Event) => void;

interface SelectProps {
    name: string;
    value: any;
    initialValue?: any;
    label?: any;
    onChange: (option: any) => void;
    onSelect?: (option: any) => void;
    disabled?: boolean;
    options: any[];
    hideOnNoOptions?: boolean;
    isEditable?: boolean;
    validation?: InputValidation;
    maxOptions?: number;
    maxOptionsReachedMessage?: string;
    noOptionsMessage?: string;
    autofocus?: boolean;
    getOptionLabel: (option: any) => string;
    getOptionSubLabel?: (option: any) => string;
    getOptionValue: (option: any) => string;
    onInputChange: (option: any) => void;
    placeholder: string;
    onBlur: (event: React.FormEvent<HTMLInputElement>) => void;
}

class Select extends Component<SelectProps> {
    static defaultProps = {
        autoFocus: false,
        maxOptions: 100,
    };

    render() {
        const {
            name,
            value,
            label,
            options,
            onChange,
            disabled,
            getOptionLabel,
            getOptionSubLabel,
            getOptionValue,
            hideOnNoOptions,
            onInputChange,
            placeholder,
            validation,
            maxOptions = 100,
            maxOptionsReachedMessage,
            noOptionsMessage,
            onBlur,
            isEditable,
            initialValue,
        } = this.props;

        const maxOptionsReached = options.length > maxOptions;
        const showOptions = hideOnNoOptions ? options.length > 0 : true;
        return (
            <Wrapper>
                <Downshift
                    onSelect={(selection) => {
                        onChange(selection);
                    }}
                    onChange={(selection) => {
                        onChange(selection);
                    }}
                    selectedItem={isEditable ? value : undefined}
                    itemToString={(item) => (item ? getOptionLabel(item) : "")}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        isOpen,
                        inputValue,
                        openMenu,
                        getToggleButtonProps,
                        selectedItem,
                    }) => {
                        return (
                            <div>
                                {!isOpen && (
                                    <OpenButton
                                        {...getToggleButtonProps()}
                                        aria-label={"toggle menu"}
                                        disabled={disabled}
                                        className={value ? "" : "placeholder"}
                                    >
                                        {value || placeholder}
                                        {value &&
                                            selectedItem &&
                                            getOptionSubLabel && (
                                                <OptionSublabel>
                                                    {getOptionSubLabel(
                                                        selectedItem,
                                                    )}
                                                </OptionSublabel>
                                            )}
                                        {value &&
                                            !selectedItem &&
                                            initialValue &&
                                            getOptionSubLabel && (
                                                <OptionSublabel>
                                                    {getOptionSubLabel(
                                                        initialValue,
                                                    )}
                                                </OptionSublabel>
                                            )}
                                    </OpenButton>
                                )}
                                {isOpen && (
                                    <Input
                                        {...getInputProps({
                                            placeholder,
                                            onChange: onInputChange,
                                            label,
                                            name,
                                            onBlur,
                                        })}
                                        value={value}
                                        autoFocus
                                        disabled={disabled}
                                        validation={validation}
                                        autocomplete="off"
                                    />
                                )}
                                {showOptions && (
                                    <Options
                                        {...getMenuProps()}
                                        open={isOpen}
                                        hasLabel={label !== undefined}
                                    >
                                        {isOpen
                                            ? options
                                                  .filter((item, index) => {
                                                      return (
                                                          !maxOptions ||
                                                          index < maxOptions
                                                      );
                                                  })
                                                  .map((item, index) => {
                                                      return (
                                                          <Option
                                                              {...getItemProps({
                                                                  key: getOptionValue(
                                                                      item,
                                                                  ),
                                                                  index,
                                                                  item,
                                                              })}
                                                          >
                                                              <OptionLabel
                                                                  title={getOptionLabel(
                                                                      item,
                                                                  )}
                                                              >
                                                                  {getOptionLabel(
                                                                      item,
                                                                  )}
                                                              </OptionLabel>
                                                              {getOptionSubLabel && (
                                                                  <OptionSublabel>
                                                                      {getOptionSubLabel(
                                                                          item,
                                                                      )}
                                                                  </OptionSublabel>
                                                              )}
                                                          </Option>
                                                      );
                                                  })
                                            : null}
                                        {maxOptionsReached &&
                                            maxOptionsReachedMessage && (
                                                <MoreSpecific>
                                                    {maxOptionsReachedMessage}
                                                </MoreSpecific>
                                            )}
                                        {options.length === 0 &&
                                            inputValue &&
                                            inputValue.length > 2 && (
                                                <MoreSpecific>
                                                    {noOptionsMessage}
                                                </MoreSpecific>
                                            )}
                                    </Options>
                                )}
                            </div>
                        );
                    }}
                </Downshift>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

interface OptionsProps {
    open: boolean;
    hasLabel: boolean;
}

const Options = styled.ul<OptionsProps>`
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: ${(props) => (props.hasLabel ? "72px" : "40px")};
    z-index: ${zIndex.top};
    background-color: ${colors.background};
    box-shadow: ${shadows.small};
    border-radius: ${defaultRadius};
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);
    transform-origin: top center;
    overflow: hidden;
    ${(props) =>
        props.open
            ? `
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    `
            : `
        transform: translateY(-10%);
        opacity: 0;
        pointer-events: none;
    `}
`;

interface OptionProps {
    isPlaceholder: boolean;
}

interface OptionProps {
    selected: boolean;
}

const Option = styled.li<OptionProps>`
    margin: ${distances.micro};
    padding: ${distances.small12} ${distances.small12};
    white-space: nowrap;
    cursor: pointer;
    border-radius: ${defaultRadius};

    ${(props) =>
        props["aria-selected"]
            ? `
        background-color: ${palette.primary[500]};
        color: white;
    `
            : `
        background-color: inherit;
    `};

    &:hover {
        background-color: ${palette.primary[500]};
        color: white;
    }
`;

const MoreSpecific = styled.li`
    margin: 0;
    padding: ${distances.tiny} 16px;
    white-space: nowrap;
    font-size: 0.7em;
    background-color: ${colors.backgroundAlt};
`;

const OptionLabel = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const OptionSublabel = styled.div`
    font-size: 0.7em;
    color: ${colors.textSecondary};
`;

const OpenButton = styled.button`
    border: none;
    outline: none;
    border: ${border.normal} solid ${palette.neutral[400]};
    border-radius: ${defaultRadius};
    background: ${colors.background};
    padding: ${distances.tiny} ${distances.small12};
    width: 100%;
    -moz-appearance: textfield;
    text-align: left;
    min-height: 40px;
    cursor: text;

    ${(props) => (props.prefix ? `padding-left: calc(${distances.small} + ${props.prefix.length * 12}px);` : "")}

    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: ${colors.textSecondary};
    }

    &:focus {
        outline: none;
        border: ${border.normal} solid ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};

    }
    &.invalid,
    &:focus .invalid {
        border: ${border.normal} solid ${palette.destructive[300]};
    }

    &.invalid:focus {
        box-shadow: 0 0 0 3px ${palette.destructive[100]};
    }

    &.valid,
    &:focus .valid {
        border: ${border.normal} solid ${palette.success[300]};
    }

    &.valid:focus {
        box-shadow: 0 0 0 3px ${palette.success[100]};
    }

    &:disabled {
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }
`;

export default Select;
