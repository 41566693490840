import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { logout } from "../../../auth/cognito";
import { Button } from "../../../components/Buttons";
import Modal from "../../../components/Modal";
import { H2, P } from "../../../components/Typography";
import { distances, grid } from "../../../styles/constants";

const InvalidSession = ({ t }: WithTranslation) => (
    <Modal solidBackdrop>
        <H2>{t("errors.invalid_session.title")}</H2>
        <ErrorWrapper>
            <P>{t("errors.invalid_session.description")}</P>
        </ErrorWrapper>
        <Button onClick={() => logout(false)}>
            {t("errors.invalid_session.call_to_action")}
        </Button>
    </Modal>
);

const ErrorWrapper = styled.div`
    width: ${grid.spans.span6};
    padding-top: ${distances.normal};
    padding-bottom: ${distances.normal};
    font-size: 14px;
`;

export default withTranslation()(InvalidSession);
