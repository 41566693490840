import { isValidNumber } from "libphonenumber-js";

const isEmptyNumber = (value: string) =>
    !value.replace(/^\+[0-9]{1,3}/, "").trim().length;

/**
 * Validates a phone number
 *
 * First message is checked up to `libphonenumber` (old behaviour)
 *
 * @param messageInvalid - Error message returned if the phone number is invalid
 * @param messageRequired - Optional error message returned if the phone number minus the country code prefix is empty
 */
export const validatePhoneNumber = (
    messageInvalid: string,
    messageRequired?: string,
): ((val: any) => string | undefined) => {
    return (value: any) => {
        if (!value) {
            return;
        }
        const isValid = isValidNumber(value);
        if (!isValid) {
            if (isEmptyNumber(value) && !!messageRequired) {
                return messageRequired;
            }
            return messageInvalid;
        }
    };
};
