import React from "react";
import styled from "styled-components/macro";
import { globalMobileBreakpoint, palette } from "../../styles/constants";

import docs from "./icons/docs-active.svg";
import help from "./icons/help-active.svg";

const iconMap = {
    docs,
    help,
};

interface AppBarIconProps extends React.DOMAttributes<HTMLSpanElement> {
    icon: keyof typeof iconMap;
    href: string;
    title: string;
    className?: string;
}

const AppBarIconExternalLink = ({ icon, href, title }: AppBarIconProps) => {
    return (
        <AppBarIconElem href={href} icon={iconMap[icon]} target="_blank">
            <StyledP>{title}</StyledP>
        </AppBarIconElem>
    );
};

interface AppBarIconElemProps {
    icon: string;
}

const StyledP = styled.p`
    margin: 0;
    color: ${palette.neutral[600]};
    font-size: 14px;
`;

const AppBarIconElem = styled.a<AppBarIconElemProps>`
    position: relative;
    background: transparent;
    width: inherit;
    display: block;
    text-decoration: none;
    user-select: none;
    margin: 0;
    padding: 8px 24px 8px 64px;
    line-height: 225%;
    background-image: url(${(props) => props.icon});
    background-repeat: no-repeat;
    background-position: 24px;
    border-left: 1px solid ${palette.neutral[300]};
    color: ${palette.neutral[600]};

    @media (max-width: ${globalMobileBreakpoint}px) {
        width: auto;
        height: 48px;
        background-position: 10%;
        border-left: none;
        border-bottom: 1px solid transparent;
        background-image: none;
        padding: 14px 12px;
        display: flex;
        line-height: 20px;
    }
`;

export default AppBarIconExternalLink;
