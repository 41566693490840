export enum Causes {
    FetchException = "FetcException",
    HandlerRaisedException = "HandlerRaisedException",
    UnhandledStatus = "UnhandledStatus",
    AccessTokenExpired = "AccessTokenExpired",
}

export interface HttpError {
    status?: number;
    statusText?: string;
    message?: string;
    "request-id"?: string;
    cause?: Causes;
    method?: string;
    url?: string;
}
