import React from "react";

const Delete = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M15.4445 6.11111L14.7699 15.5553C14.7118 16.3693 14.0344 17 13.2183 17H6.78179C5.96569 17 5.28834 16.3693 5.23019 15.5553L4.55561 6.11111M8.4445 9.22222V13.8889M11.5556 9.22222V13.8889M12.3334 6.11111V3.77778C12.3334 3.34822 11.9852 3 11.5556 3H8.4445C8.01494 3 7.66672 3.34822 7.66672 3.77778V6.11111M3.77783 6.11111H16.2223"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Delete;
