import deepmerge from "deepmerge";
import { getValueAt } from "../getValueAt";
/**
 * Helps with validating nested objects in forms
 */

const arrItemRex = /\[([0-9]+)]/;

type CombineMergeOptions = {
    clone?: boolean;
    isMergeableObject(value: object): boolean; // Will always be put on by
};

// https://github.com/TehShrike/deepmerge/tree/v3.3.0#combine-array
const emptyTarget = (value: any) => (Array.isArray(value) ? [] : {});
const clone = (value: any, options: CombineMergeOptions) =>
    deepmerge(emptyTarget(value), value, options);

const combineMerge = (
    target: any[],
    source: any[],
    options: CombineMergeOptions,
) => {
    const destination = target.slice();

    source.forEach((item, index) => {
        if (typeof destination[index] === "undefined") {
            const cloneRequested = options.clone !== false;
            const shouldClone =
                cloneRequested && options.isMergeableObject(item);
            destination[index] = shouldClone ? clone(item, options) : item;
        } else if (options.isMergeableObject(item)) {
            destination[index] = deepmerge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
            destination.push(item);
        }
    });
    return destination;
};

const mergeError = (path: string, message: string, errors: any) => {
    const trail = path.split(".");
    const reversedTail = trail.reverse();
    const newError = reversedTail.reduce((acc: any, crumb) => {
        if (!acc) {
            return { [crumb]: message };
        }
        const match = crumb.match(arrItemRex);
        if (match && match[1]) {
            const keyName = crumb.replace(arrItemRex, "");
            const idx = parseInt(match[1]);
            const arr = new Array(idx + 1).fill({});
            arr[idx] = acc;
            return { [keyName]: arr };
        }
        return { [crumb]: acc };
    }, null);
    return deepmerge(errors, newError, { arrayMerge: combineMerge });
};

export const validate = (
    path: string,
    validator: (value: any, values?: any) => string | undefined,
    values: any,
    errors: any,
) => {
    const value = getValueAt(path, values);
    const result = validator(value, values);
    if (result) {
        return mergeError(path, result, errors);
    }
    return errors;
};
