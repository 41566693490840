import React, { useState } from "react";

import PlaceholderApp from "../../../components/Placeholders/App";

import useErrorExternalStore from "../../hooks/useErrorExternalStore";
import { Causes, HttpError } from "../../types";
import ErrorModal from "./ErrorModal";
import InvalidSession from "./InvalidSession";

interface WithErrorHandlerProps {
    children?: React.ReactNode;
}

const getErrorComponent = (
    error: HttpError | undefined,
    dismissError: () => void,
) => {
    if (error?.cause === Causes.FetchException) {
        return (
            <ErrorModal
                namespace="http_fetch_error"
                error={error}
                dismissError={dismissError}
            />
        );
    }
    if (error?.cause === Causes.HandlerRaisedException) {
        return (
            <ErrorModal
                namespace="http_handler_error"
                error={error}
                dismissError={dismissError}
            />
        );
    }
    if (error?.cause === Causes.AccessTokenExpired) {
        return <InvalidSession />;
    }
    switch (error?.status) {
        case 401: {
            return <InvalidSession />;
        }
        case 403: {
            return (
                <ErrorModal
                    namespace="http_403_error"
                    error={error}
                    dismissError={dismissError}
                />
            );
        }
        case 500: {
            return (
                <ErrorModal
                    namespace="http_500_error"
                    error={error}
                    dismissError={dismissError}
                />
            );
        }
        case 502: {
            return (
                <ErrorModal
                    namespace="http_502_error"
                    error={error}
                    dismissError={dismissError}
                />
            );
        }
        case 503: {
            return (
                <ErrorModal
                    namespace="http_503_error"
                    error={error}
                    dismissError={dismissError}
                />
            );
        }
        case 504: {
            return (
                <ErrorModal
                    namespace="http_504_error"
                    error={error}
                    dismissError={dismissError}
                />
            );
        }
        default: {
            return (
                <ErrorModal
                    namespace="http_handler_error"
                    error={error}
                    dismissError={dismissError}
                />
            );
        }
    }
};

const getErrorVariant = (error: HttpError | undefined) =>
    [
        error?.method,
        error?.url,
        error?.status,
        error?.message,
        error?.["request-id"],
    ]
        .filter((x) => x)
        .join("-");

const WithErrorHandler = ({ children }: WithErrorHandlerProps) => {
    const error = useErrorExternalStore();
    const [dismissed, setDismissed] = useState(new Set());
    const variant = getErrorVariant(error?.httpError);

    // We keep the dismissed errors out of the redux store to avoid re-rendering
    // the view that prompted the error.
    const dismissError = () => {
        if (error?.hasError) {
            console.log("Dismissing error", error, dismissed);
            dismissed.add(variant);
            setDismissed(new Set(dismissed));
        }
    };

    if (error?.hasError && !dismissed.has(variant)) {
        // The application has an unhandled error when fetching data.

        const ErrorComponent = getErrorComponent(error.httpError, dismissError);
        if (
            error.httpError?.status === 401 ||
            error.httpError?.cause === Causes.AccessTokenExpired
        ) {
            // If the session is invalid do not leak sensitive data, hide actual child content and show the placeholder instead.
            return (
                <>
                    <PlaceholderApp />
                    {ErrorComponent}
                </>
            );
        }
        // Show application with current state and an error message.
        return (
            <>
                {children}
                {ErrorComponent}
            </>
        );
    }
    return <>{children}</>;
};

export default WithErrorHandler;
