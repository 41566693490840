import React from "react";

const Pdf = () => (
    <svg
        height="24"
        width="16"
        viewBox="0 0 12 16"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m8 0 4 4v11c0 .5522847-.4477153 1-1 1h-10c-.55228475 0-1-.4477153-1-1v-14c0-.55228475.44771525-1 1-1zm-.99 1h-6.01v14h10v-10.003l-2.96294504-.00926954c-.54715305-.00169402-.9913515-.44281176-.99685339-.98993977zm-2.01945162 5.42295489c.15008561-.55307223 1.20068488-.63431448 1.33200979.18435741.14695883.48120408-.04690175 1.24675604-.16884631 1.74983304.25326947.66556149.61284957 1.11864326 1.15065634 1.44673696.54406034-.06874344 1.63218101-.1718586 2.0136486.2031056.32831227.3280937.25014269 1.2092596-.54718712 1.2092596-.46276396 0-1.15378312-.2124797-1.74474521-.5311993-.67538525.1124893-1.43832043.3968372-2.13871994.6280651-1.56651856 2.6997424-2.48266613 1.4686099-2.37948228.974907.12507134-.6249404.9693029-1.121768 1.59465961-1.4436123.32831227-.5749451.79420302-1.57797442 1.10688137-2.32477817-.23138198-.89366473-.35645332-1.59359794-.21887485-2.09667494zm-.91614757 4.92140541c-.18448023.1718586-.79107624.6655615-.9505422 1.0936457 0 .0218729.35645332-.146861.9505422-1.0936457zm1.61029352-2.30290525c-.2094945.55307222-.46901753 1.18113725-.77231554 1.70921195.49715859-.1906068 1.06310641-.4655806 1.71347738-.5999428-.35019975-.25935025-.68163881-.63743917-.94116184-1.10926915zm3.3425316 1.55297685c.11256421-.0749929-.06878923-.324969-1.16629026-.2437268 1.00995109.4312089 1.16629026.2437268 1.16629026.2437268zm-3.35191195-4.11210768c-.07816959 0-.08129637.8405448.0562821 1.27487836.1532124-.27184906.17509988-1.27487836-.0562821-1.27487836zm2.33868602-5.05232422.02615101 2.55773525 2.54084899.00726475z"
            fill="#0552e0"
        />
    </svg>
);

export default Pdf;
