import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components/macro";

import { Button, ButtonGroup } from "../../components/Buttons";
import Card from "../../components/Card";
import Page from "../../components/Page";
import { P } from "../../components/Typography";
import { distances } from "../../styles/constants";

interface UncaughtComponentErrorProps extends RouteComponentProps {}

const UncaughtComponentError = ({ history }: UncaughtComponentErrorProps) => {
    const { t } = useTranslation();
    return (
        <Page title={t("errors.component_error.title")}>
            <Card>
                <P>{t("errors.component_error.description")}</P>
                <br />
                <P>
                    {t(
                        "errors.component_error.developers_are_notified_and_it_will_be_fixed",
                    )}
                </P>

                <ButtonWrapper>
                    <Button
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        {t(
                            "errors.component_error.browser_back_call_to_action",
                        )}
                    </Button>
                    <Button
                        className="alt"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        {t(
                            "errors.component_error.reload_window_call_to_action",
                        )}
                    </Button>
                </ButtonWrapper>
            </Card>
        </Page>
    );
};

export default withRouter(UncaughtComponentError);

const ButtonWrapper = styled(ButtonGroup)`
    margin-top: ${distances.normal};
`;
