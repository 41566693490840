import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Accordion from "../../../components/Accordion/Accordion";
import AccordionControl from "../../../components/Accordion/AccordionControl";
import AccordionItem from "../../../components/Accordion/AccordionItem";
import AccordionPanel from "../../../components/Accordion/AccordionPanel";
import { ButtonGroup } from "../../../components/Buttons";
import { H2, P } from "../../../components/Typography";
import View from "../../../components/View";
import ExclamationIconSvg from "../../../payout/admin/bankfile/icons/ExclamationIcon.svg";
import { defaultRadius, distances, palette } from "../../../styles/constants";

type AccessDeniedContentProps = {
    error?: React.ReactNode;
    title: string;
    description: string;
    buttons?: React.ReactNode;
};

export default function AccessDeniedContent({
    error,
    title,
    description,
    buttons,
}: AccessDeniedContentProps) {
    const { t } = useTranslation();
    return (
        <View
            gap={24}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
        >
            <View
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <View
                    justify="space-between"
                    alignItems="center"
                    pb={distances.small}
                >
                    <H2>{title}</H2>
                    <IconWrapper>
                        <ExclamationIconSvg />
                        <P>403</P>
                    </IconWrapper>
                </View>
                <P>{description}</P>
                <View
                    pt={distances.small}
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Accordion>
                        <AccordionItem value="error-details">
                            <AccordionControl>
                                <P>{t("errors.error_details")}</P>
                            </AccordionControl>
                            <AccordionPanel>{error}</AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </View>
            </View>
            <ButtonGroup>{buttons}</ButtonGroup>
        </View>
    );
}

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${palette.destructive[100]};
    gap: ${distances.tiny};
    svg {
        color: ${palette.destructive[500]};
        width: 30px;
        height: 30px;
    }
    p {
        color: ${palette.destructive[500]};
        font-size: 20px;
        font-weight: bold;
    }
    &:has(p) {
        svg {
            width: 24px;
            height: 24px;
        }
        border-radius: ${defaultRadius};
        width: auto;
        height: auto;
        padding: ${distances.tiny} ${distances.small};
    }
`;
