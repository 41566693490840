import createExternalStore from "../../common/utils/createExternalStore";
import { Causes, HttpError } from "../types";

type ErrorExternalStore = {
    hasError: boolean;
    httpError: HttpError | undefined;
};

const initialState: ErrorExternalStore = {
    hasError: false,
    httpError: undefined,
};

const errorExternalStore = createExternalStore(
    initialState,
    {
        setError: (state, httpError: HttpError) => {
            if (
                !state.hasError ||
                httpError.cause === Causes.AccessTokenExpired
            ) {
                return {
                    hasError: true,
                    httpError,
                };
            } else {
                return {
                    ...state,
                    httpError,
                };
            }
        },
        clearErrors: () => initialState,
    },
    "ERROR_EXTERNAL_STORE",
);

export default errorExternalStore;
