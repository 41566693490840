import React from "react";

const Download = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.875 16.125C3.875 15.6418 4.26675 15.25 4.75 15.25H15.25C15.7333 15.25 16.125 15.6418 16.125 16.125C16.125 16.6082 15.7333 17 15.25 17H4.75C4.26675 17 3.875 16.6082 3.875 16.125ZM6.75628 9.38128C7.09799 9.03957 7.65201 9.03957 7.99372 9.38128L9.125 10.5126L9.125 3.875C9.125 3.39175 9.51675 3 10 3C10.4832 3 10.875 3.39175 10.875 3.875L10.875 10.5126L12.0063 9.38128C12.348 9.03957 12.902 9.03957 13.2437 9.38128C13.5854 9.72299 13.5854 10.277 13.2437 10.6187L10.6187 13.2437C10.4546 13.4078 10.2321 13.5 10 13.5C9.76794 13.5 9.54538 13.4078 9.38128 13.2437L6.75628 10.6187C6.41457 10.277 6.41457 9.72299 6.75628 9.38128Z"
            fill="currentColor"
        />
    </svg>
);
export default Download;
