export const validateLength = (length: number, message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = value.length === length;
        if (!isValid) {
            return message;
        }
    };
};

export const validateMinLength = (length: number, message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = value.length >= length;
        if (!isValid) {
            return message;
        }
    };
};

export const validateMaxLength = (length: number, message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = value.length <= length;
        if (!isValid) {
            return message;
        }
    };
};
