import styled from "styled-components/macro";
import { globalMobileBreakpoint } from "../styles/constants";

type MainElementProps = {
    noPadding?: boolean;
    noNavbar?: boolean;
};

const MainElement = styled.main<MainElementProps>`
    min-height: 100dvh;
    display: flex;
    flex-direction: row;
    align-self: stretch;
    flex: 1;
    padding-top: var(--topbar-height);
    padding-left: var(--navbar-width);
    position: relative;
    transition: padding 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    ${(props) => props.noPadding && "padding: 0;"}
    ${(props) => props.noNavbar && "padding-left: unset;"}
    @media (max-width: ${globalMobileBreakpoint}px) {
        flex-direction: column;
        padding-left: unset;
    }
`;

type MainProps = {
    children: React.ReactNode;
} & MainElementProps;

export default function Main({ children, ...props }: MainProps) {
    return <MainElement {...props}>{children}</MainElement>;
}
