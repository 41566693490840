/* Danish CVR number validation from stdnum package */
const splitAt = (value: string, ...points: number[]) => {
    const parts = [0, ...points].map((p, idx) => {
        const nvalue = idx >= points.length ? value.length : points[idx];
        const np = nvalue < 0 ? value.length + nvalue : nvalue;
        const pp = p < 0 ? value.length + p : p;
        return value.substr(pp, np - pp);
    });
    return parts.filter((v) => v.length !== 0);
};

const sumDigits = (value: number) => {
    let local = value;
    let sum = 0;
    while (local) {
        sum += local % 10;
        local = Math.floor(local / 10);
    }
    return sum;
};

const weightedSum = (
    value: string,
    {
        alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        reverse = false,
        weights = [1],
        modulus = 0,
        sumByDigit = false,
    },
) => {
    const wlen = weights.length;
    const nums = value.split("").map((v) => alphabet.indexOf(v));
    const weighted = (reverse ? nums.reverse() : nums).map(
        (n, i) => n * weights[i % wlen],
    );
    return weighted.reduce((acc, v) => {
        let vv = v;
        while (vv < 0) {
            vv += modulus;
        }
        if (sumByDigit && vv > 9) {
            return (acc + sumDigits(vv)) % modulus;
        }
        return (acc + vv) % modulus;
    }, 0);
};

export const cvr = (value: string): boolean => {
    if (value.length !== 8) {
        return false;
    }
    const [front, check] = splitAt(value, -1);
    const sum = weightedSum(front, {
        modulus: 11,
        weights: [2, 7, 6, 5, 4, 3, 2, 1],
    });
    if (String((11 - sum) % 10) !== check) {
        return false;
    }
    return true;
};
