import React from "react";

const ArrowLeft = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M8.44444 15.4444L3 9.99999M3 9.99999L8.44444 4.55554M3 9.99999L17 9.99999"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ArrowLeft;
