import React from "react";

const CheckCircle = () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M11.2928932,4.66789322 L6.75,9.21078644 L4.70710678,7.16789322 L3.29289322,8.58210678 L6.75,12.0392136 L12.7071068,6.08210678 L11.2928932,4.66789322 Z"
                id="checkmark"
                fill="#0652E0"
            ></path>
        </g>
    </svg>
);

export default CheckCircle;
