import React from "react";

const Snail = () => (
    <svg
        width="24px"
        height="14px"
        viewBox="0 0 24 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="snail" fill="currentColor" fillRule="nonzero">
                <path
                    d="M6.83984373,13.4687596 C11.1445312,13.4062596 13.1640625,13.4687596 16.5703125,13.4296971 C17.6640625,13.4804784 18.5742187,12.7500091 18.7382812,11.6093833 C18.921875,10.4648512 19.4101562,8.53125609 19.9492187,8.33984973 C20.875,7.92578693 22.09375,8.00781821 22.5039062,6.9492237 C22.890625,5.8984417 22.40625,4.511722 21.2773437,4.2265655 C20.4726563,3.43359621 19.1015625,2.92187708 18.1210938,3.70312767 C16.3359375,4.88281597 15.3359375,6.90234868 14.6914063,8.89844388 C14.7851562,9.30078788 14.859375,9.86328831 14.5195313,9.92578832 C13.859375,10.0429759 11.4960938,9.41797547 8.85546874,10.5312575 C6.79296876,11.3984456 3.92187499,11.4648519 2.02343751,12.1445399 C1.4375,12.3750088 0.109374982,12.5039152 0.406250012,13.2461032 C0.433593746,13.4765721 4.99999999,13.5000096 6.83984373,13.4687596 Z"
                    id="Path"
                    stroke="currentColor"
                    strokeWidth="0.741306452"
                ></path>
                <path
                    d="M5.98828581,6.44923872 C6.30078582,6.53126999 6.03125454,6.91798885 5.84766073,6.97658264 C5.35937944,7.136739 4.96484805,6.61330125 4.94531682,6.1679886 C4.90625426,5.37501957 5.67187945,4.82423816 6.40234834,4.85939438 C7.46875483,4.91408189 8.17578631,5.98048858 8.07422379,7.00783268 C7.93750497,8.37892696 6.59766094,9.26955228 5.29687938,9.09377091 C3.66406649,8.87502086 2.61328506,7.21486399 2.85937884,5.60939464 C3.15234761,3.66017519 5.08203554,2.4218935 6.95312977,2.74611239 C9.15625525,3.12111252 10.5507868,5.38283212 10.1601617,7.56642664 C9.70703659,10.0898651 7.18359858,11.6797094 4.74609804,11.2109592 C1.97656616,10.6758028 0.234378219,7.80861426 0.77344086,5.04689449 C1.37500347,1.94923708 4.496098,0.00783009515 7.50391112,0.628924123 C10.8398494,1.31642431 13.0898499,4.30861297 13.5703187,7.83205174 C14.3242251,8.52345821 15.0586004,9.75002116 14.4101627,9.8359587 C11.7656308,9.61330237 10.242193,10.1953338 8.53906758,10.3789276"
                    id="Path"
                    stroke="#FFFFFF"
                    strokeWidth="0.74130867"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M21.160156,0.925781 C20.730469,0.933594 20.558594,1.40625 20.476562,1.75 C19.765625,2.5625 19.054688,3.378906 18.339844,4.195312 C18.988281,4.050781 19.6875,4.613281 20.277344,4.222656 C20.828125,3.515625 21.136719,2.550781 21.886719,2.046875 C22.316406,1.5 21.765625,0.859375 21.160156,0.925781 Z"
                    id="Path"
                ></path>
            </g>
        </g>
    </svg>
);

export default Snail;
