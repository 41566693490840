import React from "react";

const ArrowLeftStop = () => (
    <svg
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="arrow_left_stop"
                transform="translate(7.000000, 11.000000)"
                fill="#1BC77E"
            >
                <g id="arrow_left" transform="translate(5.000000, 0.000000)">
                    <polygon
                        id="arrow"
                        transform="translate(10.519783, 9.000000) rotate(90.000000) translate(-10.519783, -9.000000) "
                        points="11.8342169 14.1782837 16.949747 8.82010312 18.8531159 10.6206227 10.5259885 19.4802166 2.18644916 10.6213704 4.08840236 8.81935542 9.21416266 14.1798109 9.21416266 -1.4802165 11.8342169 -1.4802165"
                    ></polygon>
                </g>
                <g id="arrow_left">
                    <polygon
                        id="arrow"
                        points="3 17.9982446 2.45064879e-15 18 2.45064879e-15 0 3 0"
                    ></polygon>
                </g>
            </g>
        </g>
    </svg>
);

export default ArrowLeftStop;
