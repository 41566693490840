import { cin, ssn } from "swe-validation";
import { cvr } from "../cvr";
import mod11 from "../mod11";

export const validateNorwegianOrgNumber = (message: string) => {
    return (org_number: string) => {
        if (!org_number) {
            return;
        }
        if (org_number) {
            const trimmed = org_number.replace(/\s/g, "");
            const isDigits = /^\d+$/.test(trimmed);
            if (!isDigits) {
                return message;
            }
            try {
                const numbers = parseInt(trimmed).toString();
                if (
                    numbers.length === 9 &&
                    mod11(numbers) === parseInt(numbers.substr(8))
                ) {
                    return;
                }
            } catch {}
        }
        return message;
    };
};

const toDigits = (value: string) => {
    const trimmed = value.replace(/([\s|-])/g, "");
    const isDigits = /^\d+$/.test(trimmed);
    return isDigits ? trimmed : undefined;
};

export const validateSwedishOrgNumber = (message: string) => {
    // Allow regular company number and sole proprietorships
    return (org_number: string) => {
        if (!org_number) {
            return;
        }
        if (org_number) {
            const numbers = toDigits(org_number);
            if (!numbers) {
                return message;
            }
            try {
                // @ts-ignore - typings for the library are wrong, functions takes a string not a number
                const result = cin(numbers).isValid || ssn(numbers).isValid;
                if (result) {
                    return;
                }
            } catch {}
        }
        return message;
    };
};

export const validateDanishOrgNumber = (message: string) => {
    return (org_number: string) => {
        if (!org_number) {
            return;
        }
        if (org_number) {
            const numbers = toDigits(org_number);
            if (!numbers) {
                return message;
            }
            try {
                const result = cvr(numbers);
                if (result) {
                    return;
                }
            } catch {}
        }
        return message;
    };
};

export const getOrgNumberValidator = (
    country: string,
    errorMessage: string,
) => {
    if (country === "NO") {
        return validateNorwegianOrgNumber(errorMessage);
    } else if (country === "SE") {
        return validateSwedishOrgNumber(errorMessage);
    } else if (country === "DK") {
        return validateDanishOrgNumber(errorMessage);
    }
    return (value: string) => {
        return undefined;
    };
};
