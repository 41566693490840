export enum ValidationState {
    Pristine = "pristine",
    Valid = "valid",
    Invalid = "invalid",
    Warning = "warning",
}

export interface InputValidation {
    state: ValidationState;
    message?: string | JSX.Element;
}
