import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { InputValidation, ValidationState } from ".";
import { palette } from "../../styles/constants";
import errorSvg from "./icons/error.svg";

const getClassName = (className: string, error: boolean, warning: boolean) => {
    if (error) {
        return `${className} error`;
    }
    if (warning) {
        return `${className} warning`;
    }
    return className;
};

interface BorderInputProps {
    name: string;
    label?: any;
    placeholder: string;
    value: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    type?: string;
    validation?: InputValidation;
    rightAligned?: boolean;
    prefix?: string;
    postfix?: string;
    autoComplete?: string;
    maxlength?: number;
    className?: string;
    autoFocus?: boolean;
    [key: string]: any;
    readOnly?: boolean;
    required?: boolean;
}

export default function BorderInput(props: BorderInputProps) {
    const [inputId] = useState(
        `input-${Math.random().toString(36).substring(2)}`,
    );
    const [isFocused, setIsFocused] = useState(props.autoFocus);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const whitelistedHtmlProps = [
        "aria-autocomplete",
        "aria-activedescendant",
        "aria-controls",
        "aria-labelledby",
    ];

    interface AnyObject {
        [key: string]: any;
    }

    const otherProps = Object.keys(props)
        .filter((key) => whitelistedHtmlProps.includes(key))
        .reduce((obj: AnyObject, key: string) => {
            obj[key] = props[key];
            return obj;
        }, {});

    const placeholderLabel = !(props.prefixWidth || props.value || isFocused);
    const showErrorLabel = Boolean(
        props.validation?.state === ValidationState.Invalid,
    );

    return (
        <InputWithLabel
            className={getClassName(
                props.className || "",
                showErrorLabel,
                false,
            )}
            isFocused={isFocused || false}
            error={showErrorLabel}
            warning={false}
        >
            {showErrorLabel && (
                <ErrorLabel htmlFor={inputId} placeholderLabel={false}>
                    <img src={errorSvg} alt="error" />{" "}
                    {props.validation?.message || props.label}
                </ErrorLabel>
            )}
            {!showErrorLabel && (
                <Label htmlFor={inputId} placeholderLabel={placeholderLabel}>
                    {props.label}{" "}
                    {!props.required && (
                        <span className="optional">{t("common.optional")}</span>
                    )}
                </Label>
            )}
            <StyledInput
                {...otherProps}
                ref={inputRef}
                id={inputId}
                autoComplete={props.autoComplete}
                autoFocus={props.autoFocus}
                value={props.value}
                type={props.type || "text"}
                name={props.name}
                disabled={props.disabled}
                onChange={props.onChange}
                onBlur={(e) => {
                    setIsFocused(false);
                    props.onBlur(e);
                }}
                onKeyDown={props.onKeyDown}
                className={props.validation ? props.validation.state : ""}
                maxLength={props.maxlength}
                $prefixWidth={props.prefix}
                $postfixWidth={
                    props.postfix || (props.tooltipText ? "36px" : "12px")
                }
                readOnly={props.readOnly}
                onFocus={() => setIsFocused(true)}
            />
        </InputWithLabel>
    );
}

type InputWithLabelProps = {
    isFocused: boolean;
    warning?: boolean;
    error?: boolean;
};

const InputWithLabel = styled.div<InputWithLabelProps>`
    transition: all 0.15s ease;
    height: 60px;
    line-height: 16px;
    z-index: ${(props) => {
        if (props.warning || props.error) {
            return "1";
        }
        if (props.isFocused) {
            return "3";
        }
        return "0";
    }};
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    background: ${(props) => {
        if (props.error) {
            return palette.destructive[100];
        }
        if (props.warning) {
            return palette.warning[100];
        }
        return "transparent";
    }};
    border: ${(props) => {
        if (props.error) {
            return `1px solid ${palette.destructive[500]}`;
        }
        if (props.warning) {
            return `1px solid ${palette.warning[500]}`;
        }
        if (props.isFocused) {
            return `1px solid ${palette.neutral[900]}`;
        }
        return `1px solid ${palette.neutral[400]}`;
    }};

    &:focus-within {
        z-index: 3;
        outline: ${(props) => {
            if (props.error) {
                return `1px solid ${palette.destructive[500]}`;
            }
            if (props.warning) {
                return `1px solid ${palette.warning[500]}`;
            }
            return `1px solid ${palette.neutral[900]}`;
        }};
    }

    &.short {
        @media (min-width: 748px) {
            width: 200px;
        }
    }
`;

type InputElementProps = {
    $prefixWidth?: string;
    $postfixWidth?: string;
};

const StyledInput = styled.input<InputElementProps>`
    transition: all 0.15s ease;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border: none;
    font-size: 16px;
    line-height: 20px; // firefox has this hardcoded as font-size * 1.2
    color: ${palette.neutral[900]};
    background: transparent;
    font-weight: 500;
    -moz-appearance: textfield;
    padding-left: ${(props) => props.$prefixWidth || "12px"};
    padding-right: ${(props) => props.$postfixWidth || "12px"};
    padding-top: 28px;
    padding-bottom: 10px;
    margin: 0;
    border-radius: inherit;

    &:focus {
        z-index: 1;
        background: transparent;
        outline: none;
    }
    &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.2) !important;
    }
    &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.2) !important;
    }
    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-outer-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
    &:-webkit-autofill {
        appearance: none;
        -webkit-text-fill-color: ${palette.neutral[900]};
        -webkit-box-shadow: 0 0 0 30px white inset;
        -webkit-background-clip: text !important;
        font-size: 16px;
    }
    &:autofill{
        appearance: none;
        -webkit-text-fill-color: ${palette.neutral[900]};
        -webkit-box-shadow: 0 0 0 30px white inset;
        -webkit-background-clip: text !important;
        font-size: 16px;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

type LabelProps = {
    placeholderLabel: boolean;
};
const Label = styled.label<LabelProps>`
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > img {
        margin-right: 4px;
    }

    transition: all 0.15s ease;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #737373;
    position: absolute;
    font-weight: 500;
    font-size: ${(props) => (props.placeholderLabel ? "16px" : "14px")};
    line-height: ${(props) => (props.placeholderLabel ? "16px" : "12px")};
    top: ${(props) => (props.placeholderLabel ? "20px" : "13px")};
    padding-bottom: 3px; // to avoid text overflow cutoff when image is present in error labels
    left: 12px;
    &:focus {
        outline: none;
    }
    &:has(+ input:-webkit-autofill) {
        top:13px;
        font-size: 14px;
    }
    &:has(+ input:autofill) {
        top: 13px;
        font-size: 14px;
        content: '';
    }
    .optional{
        margin-left: 4px;
    }

`;

const ErrorLabel = styled(Label)`
    color: ${palette.destructive[500]};
`;
