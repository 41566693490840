import React from "react";
import {
    Redirect,
    Route,
    RouteComponentProps,
    withRouter,
} from "react-router-dom";
import { isPartner } from "../../helpers";

interface MatchParams {
    accountId: string;
}

interface PartnerProps extends RouteComponentProps<MatchParams> {
    children: JSX.Element;
    fallback?: JSX.Element;
}

const WithRouterPartner = ({ children, fallback, match }: PartnerProps) => {
    if (isPartner(match.params.accountId)) {
        return children;
    }
    return fallback || null;
};

export const Partner = withRouter(WithRouterPartner);

interface PartnerRouteProps extends RouteComponentProps<MatchParams> {
    exact?: boolean;
    path: string;
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
}

const RedirectUp = () => <Redirect to="../" />;

const WithRouterPartnerRoute = (props: PartnerRouteProps) => {
    const { match, component, ...rest } = props;
    if (isPartner(match.params.accountId)) {
        return <Route component={component} {...rest} />;
    }
    return <Route component={RedirectUp} {...rest} />;
};
export const PartnerRoute = withRouter(WithRouterPartnerRoute);
