import * as React from "react";
import styled from "styled-components/macro";

import { border, colors, distances, palette } from "../../styles/constants";

export type OnChangeFunction = (
    name: string,
    nextValue: boolean,
    e: React.FormEvent<HTMLInputElement>,
) => void;

interface CheckboxProps {
    name: string;
    label?: any;
    value?: string; // Used for multiple select
    checked: boolean;
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setFieldTouched?: (
        name: string,
        isTouched: boolean,
        shouldValidate: boolean,
    ) => void;
    disabled?: boolean;
    banned?: boolean;
    topLabel?: boolean;
    [key: string]: any;
}

const Checkbox = (props: CheckboxProps) => {
    if (props.topLabel) {
        return <CheckboxWithTopLabel {...props} />;
    }
    return <InnerCheckbox {...props} />;
};

const InnerCheckbox = (props: CheckboxProps) => {
    const [focus, setFocus] = React.useState(false);
    const dataProps = Object.keys(props).reduce<Record<string, any>>(
        (acc, key) => {
            acc[key] = props[key];
            return acc;
        },
        {},
    );
    return (
        <Label
            className={props.className || ""}
            checked={props.checked}
            disabled={props.disabled || false}
            banned={props.banned || false}
            focus={focus}
        >
            <HiddenBox
                id={props.name}
                type="checkbox"
                checked={props.checked}
                name={props.name}
                value={props.value}
                disabled={props.disabled}
                onChange={(event) => {
                    props.onChange(event);
                    if (props.setFieldTouched) {
                        props.setFieldTouched(props.name, true, false);
                    }
                }}
                onBlur={(e) => {
                    setFocus(false);
                    props.onBlur && props.onBlur(e);
                }}
                onFocus={() => setFocus(true)}
                {...dataProps}
            />
            {props.label}
        </Label>
    );
};

const CheckboxWithTopLabel = (props: CheckboxProps) => {
    const checkboxProps = { ...props, label: undefined };
    return (
        <div className={props.className}>
            {props.label && (
                <TopLabel htmlFor={props.name}>{props.label}</TopLabel>
            )}
            <AfterTopLabel>
                <InnerCheckbox {...checkboxProps} />
            </AfterTopLabel>
        </div>
    );
};

const TopLabel = styled.label`
    padding: ${distances.tiny} 0;
    font-weight: 700;
    display: block;
`;

const AfterTopLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    height: 34.09px;
`;

interface LabelProps {
    checked: boolean;
    disabled: boolean;
    banned: boolean;
    focus: boolean;
}

const getBackground = (props: LabelProps) => {
    if (props.banned) {
        return `
            background-image: url('/assets/icons/ban.svg');
            background-size: contain;
        `;
    }
    if (props.checked) {
        return `
            background-color: ${colors.primary};
            background-image: url('/assets/icons/input_checked.svg');
        `;
    }
    return "";
};

const Label = styled.label<LabelProps>`
    display: inline-block;
    position: relative;
    user-select: none;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};
    text-align: left;
    padding-left: 25px;
    white-space: normal;
    word-break: break-all;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: ${distances.normal20};
        height: ${distances.normal20};
        border-radius: 4px;
        bottom: 1px;
        margin-right: ${distances.tiny};
        background-color: transparent;
        ${(props) => getBackground(props)}
        border: ${(props) => (props.checked ? "none" : `${border.normal} solid ${palette.neutral[400]}`)};
        display: inline-block;
        transition: all 100ms ease-out;
        background-repeat: no-repeat;
        background-position: center;
        ${(props) => (props.focus ? `border-color:${colors.primaryFocusOutline};` : "")}
    }

    &.half:before {
        background-image: ${(props) =>
            props.checked
                ? "url(/assets/icons/input_checked.svg)"
                : "url(/assets/icons/input_half_checked.svg)"};
    }

    &:hover:before {
        border: ${border.normal} solid ${colors.primaryHover};
        border-color: ${(props) => (props.checked ? colors.primary : colors.primaryHover)};
    }
`;

const HiddenBox = styled.input`
    opacity: 0;
    height: 0;
    width: 0;
`;

export default Checkbox;
