import cc, { CurrencyCodeRecord } from "currency-codes";
import {
    getLocaleNumeralDecimal,
    getLocaleNumeralDelimiter,
    numberFormat,
} from "../intl";

export const formatMoney = (
    lng: string | undefined,
    amount: number,
    currency: string,
) => {
    const amountString = toMonetaryAmountString(lng, amount, currency);
    return `${amountString} ${currency}`;
};

export const toMonetaryAmountString = (
    lng: string | undefined,
    amount: number,
    currency: string,
) => {
    const exponent = cc.code(currency)
        ? (cc.code(currency) as CurrencyCodeRecord).digits
        : 2;
    const normalCurrencyAmount = amount / Math.pow(10, exponent);
    const amountString = numberFormat(lng, {
        minimumFractionDigits: exponent,
    }).format(normalCurrencyAmount);
    return amountString;
};

/** 
 * 
    'Shoots in' a decimal seperator in a raw monetary amount number and returns a string
    representation of the monetary amount. 
 * 
 */
export const toUnformattedMonetaryAmountString = (
    amount: number,
    currency: string,
): string => {
    const exponent = cc.code(currency)
        ? (cc.code(currency) as CurrencyCodeRecord).digits
        : 2;
    const monetaryString = String(amount);
    const negativeAmount = monetaryString[0] === "-";
    const [int, dec] = [
        rightPadZeroOrCut(
            monetaryString.substring(
                negativeAmount ? 1 : 0,
                monetaryString.length - exponent,
            ),
            negativeAmount
                ? monetaryString.length - exponent - 1
                : monetaryString.length - exponent,
        ) || "0",
        leftPadZeroOrCut(
            monetaryString
                .substring(monetaryString.length - exponent)
                .replace("-", ""),
            exponent,
        ),
    ];
    const delimiter = exponent !== 0 ? "." : "";
    const prefix = negativeAmount ? "-" : "";
    return `${prefix}${int}${delimiter}${dec}`;
};

export const toCommonMonetaryAmountString = (
    lng: string | undefined,
    amount: number,
    currency: string,
) => {
    const exponent = cc.code(currency)
        ? (cc.code(currency) as CurrencyCodeRecord).digits
        : 2;
    const normalCurrencyAmount = amount / Math.pow(10, exponent);
    return numberFormat(lng, { minimumFractionDigits: 0 }).format(
        normalCurrencyAmount,
    );
};

const leftPadZeroOrCut = (value: string, len: number): string => {
    /*
        Cuts off characters in value after len without rounding, Left pads
        value with "0" characters so the returned value is always of length
        len.
        Eg.
        ('' , 2) -> '00',
        ('1' , 2) -> '01'
        ('11' , 2) -> '11'
        ('01' , 2) -> '01'
        ('001' , 2) -> '00'
        ('111' , 2) -> '11'
        ('9999' , 2) -> '99'
    */
    const padChar = "0";
    if (value.length >= len) {
        return value.substr(0, len);
    }
    return padChar.repeat(len - value.length) + value;
};

const rightPadZeroOrCut = (value: string, len: number): string => {
    /*
        Cuts off characters in value after len without rounding, Right pads
        value with "0" characters so the returned value is always of length
        len.

        Eg.
        ('' , 2) -> '00',
        ('1' , 2) -> '10'
        ('11' , 2) -> '11'
        ('01' , 2) -> '01'
        ('001' , 2) -> '00'
        ('111' , 2) -> '11'
        ('9999' , 2) -> '99'
    */
    const padChar = "0";
    if (value.length >= len) {
        return value.substr(0, len);
    }
    return value + padChar.repeat(len - value.length);
};

const raiseToExponentWithStrings = (num: number, exponent: number) => {
    // we raise to exponent via string manipulation,  avoiding floating point errors
    const numString = num.toString();
    const [intPart, decimalPart] = numString.split(".");
    const safeDecimalPart = decimalPart || "";
    const raisedNumberString =
        intPart + rightPadZeroOrCut(safeDecimalPart, exponent);
    return parseInt(raisedNumberString);
};

export const fromMonetaryAmountString = (
    lng: string | undefined,
    amount: string,
    currency: string,
) => {
    const unformatted = unformatNumber(lng, amount);
    const exponent = cc.code(currency)
        ? (cc.code(currency) as CurrencyCodeRecord).digits
        : 2;
    return raiseToExponentWithStrings(unformatted, exponent);
};

export const toCommonCurrencyAmount = (amount: number, currency: string) => {
    // this looks horrible but should work...
    const exponent = (cc.code(currency) || {}).digits || 0;
    const prefix = amount < 0 ? "-" : "";
    const absoluteString = amount.toString().replace("-", "");
    const dotIndex = absoluteString.indexOf(".");
    const splitAt =
        dotIndex === -1
            ? absoluteString.length - exponent
            : dotIndex - exponent;
    const firstPart = splitAt > 0 ? absoluteString.substr(0, splitAt) : "0";
    const middlePart = splitAt < 0 ? "0".repeat(Math.abs(splitAt)) : "";
    const lastPart = absoluteString
        .substr(splitAt > 0 ? splitAt : 0)
        .replace(".", "");
    const raisedNumberString = `${prefix}${firstPart}.${middlePart}${lastPart}`;
    return parseFloat(raisedNumberString);
};

export const fromQuantityString = (lng: string | undefined, value: string) => {
    return unformatNumber(lng, value);
};

function unformatNumber(lng: string | undefined, formattedNumber: string) {
    const group = getLocaleNumeralDelimiter(lng);
    const decimal = getLocaleNumeralDecimal(lng);
    let unformattedString = formattedNumber.replace(
        new RegExp("\\" + group, "g"),
        "",
    );
    unformattedString = unformattedString.replace(
        new RegExp("\\" + decimal, "g"),
        ".",
    );
    const unformattedNumber = parseFloat(unformattedString);
    return Number.isNaN(unformattedNumber) ? 0 : unformattedNumber;
}
