import { TFuncKey } from "react-i18next";
import { Dispatch } from "redux";
import { IApiError } from "../../../apiRequest";
import { createSwitchlessReducer } from "../../../util/switchlessReducer";
import { Message } from "./types";

export type MessageState = {
    messages: {
        [context: string]: Message | undefined;
    };
};

const initialState: MessageState = {
    messages: {},
};
const { reducer, actions } = createSwitchlessReducer({
    namespace: "payout.general.message",
    initialState,
    reducers: {
        addMessage: (
            state: MessageState,
            { message }: { message: Message },
        ): MessageState => ({
            ...state,
            messages: {
                ...state.messages,
                [message.context]: message,
            },
        }),
        clearMessage: (
            state: MessageState,
            { context }: { context: string },
        ): MessageState => ({
            ...state,
            messages: {
                ...state.messages,
                [context]: undefined,
            },
        }),
        clearAllMessages: (_: MessageState) => initialState,
    },
});

export { reducer };

export const addMessageAction = (message: Message) =>
    actions.addMessage({ message });

export const addApiErrorMessageAction = (
    error: IApiError,
    context = "general",
    message?: TFuncKey<"payout">,
) =>
    actions.addMessage({
        message: {
            context,
            message: message ?? error.message,
            severity: "error",
            autoHideDuration: null, // disables autohide
            requestId: error.requestId,
            variant: "filled",
        },
    });

export const addSuccessMessageAction = (
    message: TFuncKey<"payout">,
    context = "general",
) =>
    actions.addMessage({
        message: {
            context,
            message,
            severity: "success",
            variant: "outlined",
            autoHideDuration: 3000,
        },
    });

export const addInfoMessageAction = (
    message: TFuncKey<"payout">,
    context = "general",
) =>
    actions.addMessage({
        message: {
            context,
            message,
            severity: "info",
            variant: "outlined",
            autoHideDuration: 3000,
        },
    });

export const addWarningMessageAction = (
    message: TFuncKey<"payout">,
    context = "general",
) =>
    actions.addMessage({
        message: {
            context,
            message,
            severity: "warning",
            variant: "standard",
            autoHideDuration: 5000,
        },
    });

export const createMessageActions = (dispatch: Dispatch) => {
    const addCustom = (message: Message) => {
        dispatch(addMessageAction(message));
    };

    const error = (
        error: Error,
        context = "general",
        message?: TFuncKey<"payout">,
    ) => {
        dispatch(
            addMessageAction({
                context,
                message: message ?? error.message,
                severity: "error",
                autoHideDuration: null,
                variant: "filled",
            }),
        );
    };

    const success = (message: TFuncKey<"payout">, context = "general") => {
        dispatch(addSuccessMessageAction(message, context));
    };

    const info = (message: TFuncKey<"payout">, context = "general") => {
        dispatch(addInfoMessageAction(message, context));
    };

    const warn = (message: TFuncKey<"payout">, context = "general") => {
        dispatch(addWarningMessageAction(message, context));
    };

    const clear = (context: string) => {
        dispatch(actions.clearMessage({ context }));
    };

    const clearAll = () => {
        dispatch(actions.clearAllMessages());
    };

    return {
        addCustom,
        error,
        success,
        info,
        warn,
        clear,
        clearAll,
    };
};
export type MessageActions = ReturnType<typeof createMessageActions>;
