import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import App from "./App";
import authStore from "./auth/accessToken/observables/authStore";
import * as cognito from "./auth/cognito";
import { LoginContainer } from "./auth/login";
import { cognito as cognitoCtx } from "./env";
import "./index.css";
import { clearCognitoSession, getCognitoSession } from "./auth/localStorage";
import { determineMaximumScaleViewport } from "./util/dom";

determineMaximumScaleViewport();

const showError = (error: any) => {
    document.write(
        "An unexpected error occurred. Please try again. If you are using an old browser (like IE11) please try again using something more modern. If the problem persist please contact support@dintero.com ",
    );
    // rethrow error so sentry can log it
    throw error;
};

const newCognitoLogin = () => {
    // We don't have a valid user session.
    if (cognitoCtx().external) {
        // Redirect to cognito if external
        cognito.login();
    } else {
        // Render login container
        ReactDOM.render(<LoginContainer />, document.getElementById("root"));
    }
};

try {
    // Force a reload when page is loaded from bfcache
    window.onpageshow = (event: PageTransitionEvent) => {
        if (event.persisted) {
            window.location.reload();
        }
    };

    const session = getCognitoSession();
    cognito
        .authenticate(session)
        .then((authResult) => {
            if (authResult) {
                // Set user
                authStore.dispatch("setAuthenticatedUser", {
                    accountUser: authResult,
                });

                // Start react application
                ReactDOM.render(<App />, document.getElementById("root"));
            } else {
                newCognitoLogin();
            }
        })
        .catch((error) => {
            console.log("Authenticate from session failed", error);
            clearCognitoSession();
            return newCognitoLogin();
        });
} catch (error) {
    showError(error);
}
