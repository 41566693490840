interface Translations {
    [key: string]: string | Translations;
}

type LoadCallback = (error: any, result: any) => void;

class Backend {
    type: "backend";
    static type = "backend";

    constructor(services: any, options: any) {
        this.init(services, options);
        this.type = "backend";
    }

    init(services: any, options: any) {
        this.type = "backend";
    }

    read(language: string, namespace: string, callback: LoadCallback) {
        const loadTranslations: Promise<Translations> = import(
            `./locales/${namespace}/${language}.json`
        );
        loadTranslations
            .then((lng: Translations) => callback(null, lng))
            .catch((err) =>
                callback(
                    `Failed loading src/locales/${namespace}/${language}.json Error: ${err}`,
                    null,
                ),
            );
    }
}

export default Backend;
